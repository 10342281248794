import React, { Component } from 'react';
import invitationLandingService from 'api/invitationLandingService';
import {HalfCardLayout} from 'components/Layout/HalfCardLayout'
import { SetPasswordForm } from 'components/UserCreate/SetPassword';
import {ATRAIN_ROUTES,getRoute} from 'routes'
import i18next from "i18next";
import { t } from 'i18next';

export class ClaimInvitationPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      addPasswordCode:null,
      isPageVisible: false,
      step:'',
      user:{
        firstName:'',
        lastName:''
      },
      isErrorPage:false,
      errorMessage:''
    }
  }

  handleNext(o){
    var nextStep = ''
    switch (this.state.step) {
      case '':
        nextStep = 'setpassword'
        break;

      case 'setpassword':
        nextStep = 'setavatar'
        this.props.history.push(getRoute(ATRAIN_ROUTES.SET_AVATAR,{isregistration:1}));
        break;

      default:
        break;
    }

    this.setState({
      step:nextStep
    })

  }

  componentDidMount(){
    const qs = new URLSearchParams(this.props.location.search)
    const invitationToken = qs.get('t')
    //const userId = qs.get('u')

    invitationLandingService.getUnauthenticatedUserInfo(invitationToken).then(resp =>{
      
      try{
        let prj = resp.data.projectId || ''

        if(prj){
          sessionStorage.setItem('currentproject', prj)
        }

        if(resp.data.redirectToLogin){
          
          window.location.href = ATRAIN_ROUTES.LOGIN + '?prj=' + prj
          return
        }

        this.setState({
          isPageVisible: true,
          user: resp.data,
          step:'setpassword',
          addPasswordCode:resp.data.addPasswordCode,
          userId:resp.data.userId
        })
      }catch(err){
        console.log('err',err)
        this.setState({
          isPageVisible: true,
          isErrorPage:true,
          errorMessage:t(err.data)

        })
      }
      


    }).catch(err => {

    

      
      try {
        let errors = err.response.data.errors
        let errorMessage = ""
        if(errors){
          for (const e in errors) {
            errorMessage+= errors[e][0] + '\n'
          }
        }else{
          errorMessage = t(err.response.data)
        }

        this.setState({
          isPageVisible: true,
          isErrorPage:true,
          errorMessage:errorMessage

        })

      } catch (error) 
      {
        console.log(error)
        this.setState({
          isPageVisible: true,
          isErrorPage:true,
          errorMessage:'Generic link error'

        })
      }
      
    })

  }
   
  
  render() {
    const {t} = i18next
    const {errorMessage, isErrorPage, isPageVisible, user, step, addPasswordCode, userId} = this.state
    const bgtitle = t('claiminvitation.title')
    const bgtext = isErrorPage ? '' : t('claiminvitation.subtitle',{firstName:user.firstName})

    return (
     <div className='setavatar-wrapper' >
        {isPageVisible && 
        <HalfCardLayout
          halfheight={true}
          bgimage="/img/card-bg.jpg"
          bgtitle={bgtitle}
          bgtext={bgtext}
        >
        
            {step === 'setpassword' && <SetPasswordForm onSubmit={this.handleNext.bind(this)} userId={userId} addPasswordCode={addPasswordCode}/>}

            {isErrorPage &&  <div>
                <h3 className="mt-4 pb-2 text-capitalize h4 ">{t('claiminvitation.invalidurl.title')}</h3>

                <div>
                  {/*<div dangerouslySetInnerHTML={{__html: t('claiminvitation.invalidurl.message')}}></div>*/}
                  <pre className='mt-4'>
                    {errorMessage}
                  </pre>
                </div>
            </div>
          }

        </HalfCardLayout>
      }
      </div>
    );
  }
}