import { HTTP } from 'api'

const basePath = "/DevelopmentLoop/";

export default {

  improvableMarkers(assessmentId, rankingType){
    return HTTP.get(basePath.concat('ImprovableMarkers/' + assessmentId),{params:{RankingType:rankingType}})
  },
  
  getDevloop(developmentLoopId){
    return HTTP.get(basePath.concat(developmentLoopId))
  },

  create(p){
    return HTTP.post(basePath, p)
    //return HTTP.get('../../mock/foo.json')
  },

  followUp(developmentLoopId,p){
    return HTTP.patch(basePath.concat(developmentLoopId),p)
  },
  
  closeDevelopmentLoop(developmentLoopId){
    return HTTP.patch(basePath.concat('Close/',developmentLoopId));
  },
  
}