import React, { Component } from 'react';

import { Label, Input, Button} from 'reactstrap';
import i18next from "i18next";
import accountService from 'api/accountService'
//import { setProjects } from 'utils';
import {storeActions} from 'stores/storeHelpers'

export class LoginForm extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      password:'',
      username:'',
      errorMessageVisible:false,
      errorMessage:' '
    }
    this.onInputchange = this.onInputchange.bind(this);
  }

  doLogin(event){
    event.preventDefault();

    
    storeActions.setIsLoading(true)

    accountService.login(
      this.state.username,
        this.state.password
        )
        .then( resp => {


          this.props.onLoginDone()
        }).catch(err => {
          let errorMessage= ''

          switch (err.response.status) {
            case 401:
              errorMessage = i18next.t(err.response.data)
              break;
          
            default:
              errorMessage = 'Generic error'
              break;
          }

          this.setState({
            errorMessage,
            errorMessageVisible:true
          })

        }).finally(()=>storeActions.setIsLoading(false))
    
  }

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }
  
    
  get isFormFilled(){
    let valid = this.state.username != '' && this.state.password != ''
    return valid
  }

  render() {
    //const {users, competency, totalReplies} = this.state.reportData
    const {t} = i18next
    return (
      <div>
          <h3 className="mt-4 pb-2 text-capitalize h4 ">{t('login.cardTitle')}</h3>
          
          <form className="mt-4 mb-2" onSubmit={this.doLogin.bind(this)}>
						<div className="form-group">
							<Label for="password">{t('login.lblUsername')}</Label>
							<Input
                  name="username"
                  placeholder=""
                  type="email"
                  className='form-control main'

                  value={this.state.username}
                  onChange={this.onInputchange}
                />
						</div>
						<div className="form-group mb-0">
              <Label for="password">{t('login.lblPassword')}</Label>
              <Input
                  name="password"
                  placeholder=""
                  type="password"
                  className='form-control main mb-1'

                  value={this.state.password}
                  onChange={this.onInputchange}
                />
						</div>
            <div className={'fieldErrorMessage text-danger text-center mb-3 ' + (this.state.errorMessageVisible ? 'd-shown':'d-hidden')}>{this.state.errorMessage}</div>
            <Button disabled={!this.isFormFilled} className="btn btn-primary w-100">{t('login.btnLogin')}</Button>
					</form>

          {/*<p className="regular disclaimer-text text-center">By creating passcode you agree with out <a href="#">Term &amp; Condition</a> and <a href="#">Privacy policy</a></p>*/}
      </div>
    );
  }
}