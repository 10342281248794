import React, { Component } from 'react';
import { AnimatePresence } from "framer-motion"
import {motion} from "framer-motion"

export class LoadSpinner extends Component {
  
  render () {
    const className = 'loaderOverlay'
    const motionInitial = {opacity:0}
    const motionExit = {opacity:0}
    const motionAnimate = {opacity:1}

    return (
        <>
        
          <AnimatePresence>
          {this.props.active && <motion.div
            className={className}
            animate={motionAnimate}
            initial={motionInitial}
            exit={motionExit}
            transition={{ duration: .35 }}>
            <div className="lds-dual-ring"></div>
            <div className='loading-text'>LOADING...</div>
          </motion.div>}
          </AnimatePresence>
        </>
    );
  }
}


