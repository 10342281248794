import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
//import { fetchCount } from './counterAPI';

const initialState = {
  user:null,
  projects:[],
  currentProject:null,
  devloopMarkers:[],
  isLoading:false,

  errorMessageVisible:false,
  errorMessageText:''
};


export const mainSlice = createSlice({
  name: 'main',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: (state, action)  => {

      console.log('setUser', JSON.stringify(action.payload))
      //const debugProj = [{projectId:2, projectName:'Progetto A', selected:true}]
      //action.payload.projects = debugProj

      state.user = action.payload;
    },
    /*setProjects: (state, action)  => {
      state.projects = action.payload;
    },*/
    setCurrentProject:(state,action) =>{
        console.log('setCurrentProject action',action)
        state.currentProject = action.payload;
        sessionStorage.setItem('currentproject', action.payload)
    },

    setDevloopMarkers:(state,action) =>{
      state.devloopMarkers = action.payload;
    },

    setIsLoading:(state,action) =>{
      state.isLoading = action.payload;
    },

    setErrorMessage:(state,action) =>{
      state.errorMessageVisible = action.payload.visible;
      state.errorMessageText = action.payload.text;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  /*extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.value += action.payload;
      });
  },*/
});


export const { setUser, setProjects, setCurrentProject, setDevloopMarkers, setIsLoading, setErrorMessage } = mainSlice.actions;

export const avatarImageSelector = (state) => {
  try{
    return state.main.user.avatarUrl
  }catch{
    return null
  }
  
}
export const devloopMarkersSelector = (state) => {
    return state.main.devloopMarkers
}

export const userSelector = (state) => {
  return state.main.user
}


export const currentProjectSelector = (state) => {
  /*console.log('state',state.main)
  console.log('state.currentProject',state.main.currentProject)
  console.log('sessionStorage.getItem',sessionStorage.getItem('currentproject'))
  console.log('state.main.user.projects',state.main.user.projects)*/
  let projectId = state.main.currentProject || sessionStorage.getItem('currentproject') || state.main.user.projects.find(p => p.selected).id
  //console.log('||||||| getCurrentProject projectId',projectId)

  if(!state.main.user.projects.some(p => p.id == projectId)){
    projectId = state.main.user.projects[0].id
  }

  const projs = state.main.user.projects
  //console.log('getCurrentProject projs',projs)

  if(projs){
      const proj = projs.find(p => p.id == projectId)
      //console.log('getCurrentProject proj',proj)
  
      return proj
  }else{
      //console.warn('No projects found')
      return null
  }
  
}

export const isLoadingSelector = (state) => {
  return state.main.isLoading
}


export const actions = mainSlice.action

/*
export const projectsSelector = (state) => {
  try{
    if(state.main.projects.length > 0){
      console.log('AAAAAAAAAAAA');
      return state.main.projects
    }else{
      const projs = sessionStorage.getItem('userprojects')
      console.log('BBBBBBBBBBBBB');
      if(projs){
        console.log('CCCCCCCCCc', mainSlice.actions);
        const parsedProjs = JSON.parse(projs)
        mainSlice.actions.setProjects(parsedProjs)

        const curProj = parsedProjs.find(p=>p.selected) || parsedProjs[0]
        console.log('curProj',curProj);
        mainSlice.actions.setProject(curProj)


        return parsedProjs
      }else{
        console.log('DDDDDDDD');
        return []
      }
    }    
  }catch{
    return null
  }
  
}


export const currentProjectSelector = (state) => {
  try{
    return state.main.user.avatarUrl
  }catch{
    return null
  }
  
}*/


export const mainSelectors = {avatarImageSelector, currentProjectSelector, devloopMarkersSelector, isLoadingSelector, userSelector}

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
/*export const incrementIfOdd = (amount) => (dispatch, getState) => {
  const currentValue = selectCount(getState());
  if (currentValue % 2 === 1) {
    dispatch(incrementByAmount(amount));
  }
};*/

export default mainSlice.reducer;
