import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap';
import { IconCloseSquare } from 'components/icons'
import { motion } from "framer-motion"

export class SimplePopup extends Component {

  render() {
    const { className, onClose, showCloseButton, suptitle, title, subtitle, footer } = this.props

    return (
      <motion.div
        animate={{ translateY: 0 }}
        initial={{ translateY: 20 }}
        exit={{ translateY: -20 }}
        transition={{ duration: 0.35 }}>
        <Card className={'popup-element ' + (className || '') + (showCloseButton ? ' pt-4' : '')}>
          <CardBody>
            {showCloseButton && <IconCloseSquare className="close-button" color="#163345" onClick={onClose} />}
            {suptitle && <div className='supTitle'>{suptitle}</div>}
            {title && <CardTitle tag="h3" dangerouslySetInnerHTML={{ __html: title }}></CardTitle>}
            {subtitle && <CardSubtitle dangerouslySetInnerHTML={{ __html: subtitle }}></CardSubtitle>}

            {this.props.children}

            {footer && <div className='footerText'>{footer}</div>}
          </CardBody>
        </Card>
      </motion.div>
    );
  }
}
