import React, { Component } from 'react';


export class OverlayCircle extends Component {
  
  handleResize = function(){
    this.props.onWindowResize()
/*
    const height = window.innerHeight;
    const width = window.innerWidth;
    
    this.setState({
      height,
      width,
    });
*/
  }

  constructor(props) {
    super(props);
    this.state = {}
  }

  // Coputed props
  get radius() {
    
    let y = this.props.distance || 0
    
    //y = y - 0 //100 + il diametro dell'avatar

    return y > 0 ? y :0
  }
 
  componentDidMount() {
    let me = this
    window.addEventListener('resize', this.handleResize.bind(me))
   
  }


  componentWillUnmount(){
    let me = this
    window.removeEventListener('resize', this.handleResize.bind(me))
  }


  render() {
    const {avatarRadius, headerHeight, viewportSize} = this.props

    return (
    <div className='behavior-overlay-backdrop'>
        <svg  viewBox={'0 0 ' + viewportSize.width + ' ' + viewportSize.height} className="behavior-overlay" xmlns="http://www.w3.org/2000/svg" fill="#FFF" stroke="#2099F0" strokeWidth="0">
            <circle fill={this.props.circlecolor} r={this.radius} cx={viewportSize.width / 2} cy={headerHeight - (avatarRadius/2)}></circle>
        </svg>
        <span className='user-select-none behavior-overlay-text text-uppercase'>{this.props.bottomLabel}</span>
        </div>
    );
  }
}