import React, { Component } from 'react';
import { IconUserTag, IconLikeTag, IconUserTagMini, IconLikeTagMini } from 'components/icons'

export class Slider extends Component {

  /*constructor(props) {
    super(props);
    this.state = { currentCount: 0 };
  }*/

  get baseMargin() {
    return {
      marginRight: (this.props.toLeft) ? '50%' : '0%',
      marginLeft: (!this.props.toLeft) ? '50%' : '0%',
    }
  }

  get barStyle() {
    return { ...this.baseMargin, width: (this.props.amount / 2.0) + '%' }
  }

  get barStyle2() {
    return { ...this.baseMargin, width: (this.props.amount2 / 2.0) + '%' }
  }

  get tagStyle() {
    return { ...this.baseMargin, left: (this.props.amount / 2.0) + '%' }
  }

  get progressStyle() {
    return {
      alignItems: (this.props.toLeft) ? 'flex-end' : 'flex-start'
    }
  }

  render() {
    const iconTag = this.props.bigger ? <IconLikeTag className="icon-tag" color={this.props.color} /> : <IconLikeTagMini className="icon-tag" color={this.props.color} />
    const iconLike = this.props.bigger ? <IconUserTag className="icon-tag" color={this.props.color} /> : <IconUserTagMini className="icon-tag" color={this.props.color} />
    
    return (
      <div className={'slider-item ' + (this.props.toLeft ? 'to-left' : 'to-right')}>
        {this.props.title && <div className='slider-item-descr'>{this.props.title}</div>}

        <div style={this.progressStyle} className={'custom-progress' + ((this.props.bigger) ? ' bigger ' : ' ') + (this.props.barClass || '')}>
        {this.props.amount && <div style={this.barStyle} className='custom-progress-bar bar1'>{iconTag}</div>}
          {this.props.amount2 && <div style={this.barStyle2} className='custom-progress-bar bar2'>{iconLike}</div>}
        </div>

      </div>
    );
  }
}

export default Slider