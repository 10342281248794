import React, { Component } from 'react';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';
import Slider from './Slider';
import {getItems} from 'api/index';
//import { textChangeRangeIsUnchanged } from 'typescript';
import { stripHtml } from 'utils';

export class CardElement extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      currentCount: 0,
      isExpanded: false,
    }

    this.incrementCounter = this.incrementCounter.bind(this);
  }

  // Coputed props
  get enablers() {
    return this.props.competency.enablers//behaviorMarker.filter(item => item.isPositiveMarker)
  }

  get blockers() {
    return this.props.competency.blockers//behaviorMarker.filter(item => !item.isPositiveMarker)
  }
  
  componentDidMount(){
    console.log(getItems)
    /*getItems().then(res =>{
      console.log(res.data)
    //const persons = res.data;
    this.setState({
        enablers:res.data.items,
        blockers:res.data.items
    });
    })*/
    
  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  toggleExpand() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  render() {
    
    return (
      <Card className='card-element report-card'>
        <CardBody>
          <div className="cardToggler" onClick={this.toggleExpand.bind(this)}>
            <CardTitle tag="h5">{stripHtml(this.props.competency.competencyName)}</CardTitle>
          </div>

          <div className='item-list-labels'>
            {<b className={'collapsable text-pink' + (this.state.isExpanded ? ' collapsed':'')}>{stripHtml(this.props.competency.blockers.markersAverage.title)}</b>}
            <b className='float-right text-blue'>{stripHtml(this.props.competency.enablers.markersAverage.title)}</b>
          </div>
          <div className="items-list enablers-list">
            
            <Slider amount={this.props.competency.enablers.markersAverage.ratersAverageScore} amount2={this.props.competency.enablers.markersAverage.personScore} bigger={true} color="#3397D9"/>
            <div className={'card-detail ' + (this.state.isExpanded ? 'expanded' : '')}>
              {this.props.competency.enablers.markers.map((i,idx)=> <Slider key={idx.toString()} title={stripHtml(i.title)} amount={i.ratersAverageScore} amount2={i.personScore} color="#3397D9"/>)}
            </div>
          </div>

          {<b className={'collapsable text-pink d-block' + (!this.state.isExpanded ? ' collapsed':'')}>{stripHtml(this.props.competency.blockers.markersAverage.title)}</b>}
          <div className="items-list blocker-list">
          
            <Slider amount={this.props.competency.blockers.markersAverage.ratersAverageScore} amount2={this.props.competency.blockers.markersAverage.personScore} bigger={true} toLeft={true} color="#D71C8B"/>
            <div className={'card-detail ' + (this.state.isExpanded ? 'expanded' : '')}>
              {this.props.competency.blockers.markers.map((i,idx)=> <Slider key={idx.toString()} title={stripHtml(i.title)} amount={i.ratersAverageScore} amount2={i.personScore} toLeft={true}  color="#D71C8B"/>)}
            </div>
          </div>

          <div className={'card-expand-toggler' + (!this.state.isExpanded ? ' collapsed':'')} onClick={this.toggleExpand.bind(this)}></div>

          
        </CardBody>
      </Card>
    );
  }
}
