import React, { Component } from 'react';

const IconWrapper = (props) => {
  const roundClass = props.nopadding ? '' : 'rounded-button'
  const block = props.displayblock ? 'd-block-icon' : ''

  return (
    <span className={roundClass + ' custom-icon ' + block + ' ' + props.className} style={props.style} onClick={props.onClick}>
	    {props.children}
    </span>
  )
}

export default IconWrapper