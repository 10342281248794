import { HTTP } from 'api'

const basePath = "/InvitationLanding/";
//const basePath = "";

export default {
  getUnauthenticatedUserInfo(code) {
    const p = {
      code
    }

    return HTTP.get(basePath,{params:p});
    }
}