import React, { Component } from 'react';
import IconWrapper from './IconWrapper';

const IconItem = (props) => {
  const color = props.color || '#7F99AB'
  
  return (
    <IconWrapper {...props} >
	    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3814 14.6732H12.9444C14.174 14.6732 15.1666 13.6129 15.1666 12.323V3.68997C15.1666 2.39225 14.174 1.33984 12.9444 1.33984H4.05547C2.82584 1.33984 1.83325 2.39225 1.83325 3.68997V12.323C1.83325 13.6208 2.82584 14.6732 4.05547 14.6732H4.61103H12.3814Z" fill="white" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.59337 4.44542C10.1085 4.622 10.4659 5.18168 10.3519 5.74059L10.215 6.61684H11.3599C11.7452 6.61684 12.105 6.77446 12.3291 7.07857C12.5542 7.38403 12.5963 7.77546 12.4665 8.14501L11.6732 10.5579C11.586 10.884 11.3746 11.1445 11.1344 11.3203C10.8896 11.4995 10.5798 11.6168 10.2599 11.6168H8.99326C8.84497 11.6168 8.67378 11.5932 8.51424 11.5476C8.37565 11.508 8.186 11.4352 8.03448 11.2972L7.02051 10.5122L7.63268 9.72147L8.69107 10.5409L8.70367 10.5535C8.70543 10.5545 8.70773 10.5557 8.71062 10.5571C8.72723 10.5654 8.75347 10.5759 8.78896 10.5861C8.86275 10.6072 8.94155 10.6168 8.99326 10.6168H10.2599C10.3401 10.6168 10.4469 10.5842 10.5438 10.5133C10.6404 10.4427 10.692 10.3603 10.7082 10.2956L10.7126 10.2779L11.5203 7.82131L11.5224 7.81533C11.5564 7.72017 11.531 7.68113 11.5241 7.67177C11.5148 7.65921 11.4747 7.61684 11.3599 7.61684H10.0266C9.53103 7.61684 9.1207 7.18923 9.19829 6.64612L9.19921 6.63964L9.36838 5.55725L9.37184 5.5417C9.37354 5.53404 9.37516 5.50475 9.35084 5.46377C9.32661 5.42297 9.29314 5.3994 9.26848 5.39118L9.2597 5.38825L9.25103 5.385C9.24905 5.38426 9.22122 5.37709 9.17247 5.39385C9.15057 5.40137 9.1321 5.41141 9.11906 5.42082C9.11221 5.42576 9.10853 5.42929 9.10719 5.43065L7.74157 7.46242L6.91162 6.90458L8.27785 4.8719C8.41728 4.66329 8.63315 4.5218 8.84739 4.44816C9.06289 4.37408 9.33146 4.34995 9.59337 4.44542Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.13581 6.72962C6.13126 6.75317 6.12671 6.79164 6.12671 6.85013V10.1168C6.12671 10.1753 6.13126 10.2138 6.13581 10.2373C6.16564 10.2438 6.21547 10.2501 6.29338 10.2501H6.62671C6.70461 10.2501 6.75445 10.2438 6.78427 10.2373C6.78882 10.2138 6.79338 10.1753 6.79338 10.1168V6.85013C6.79338 6.79164 6.78882 6.75317 6.78427 6.72962C6.75445 6.72308 6.70461 6.7168 6.62671 6.7168H6.29338C6.21547 6.7168 6.16564 6.72308 6.13581 6.72962ZM5.4459 5.99106C5.69527 5.76088 6.02172 5.7168 6.29338 5.7168H6.62671C6.89837 5.7168 7.22482 5.76088 7.47418 5.99106C7.73337 6.23031 7.79338 6.56046 7.79338 6.85013V10.1168C7.79338 10.4065 7.73337 10.7366 7.47418 10.9759C7.22482 11.206 6.89836 11.2501 6.62671 11.2501H6.29338C6.02172 11.2501 5.69527 11.206 5.4459 10.9759C5.18671 10.7366 5.12671 10.4065 5.12671 10.1168V6.85013C5.12671 6.56046 5.18671 6.23031 5.4459 5.99106Z" fill={color}/>
        </svg>
    </IconWrapper>
  )
}

export default IconItem