import React, { Component } from 'react';
import { motion } from "framer-motion"
import { IconHand, IconCross } from 'components/icons'

export class DragItem extends Component {

  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.tooltipRef = React.createRef();

    this.state = {
      foo: 0,
      dragAction: '',
      canDrag: true,
      isTooltipShown: false,
      snapToOrigin: false,
      isRepositioning: false,
      constraint: {}
    }
  }

  onDrag(event, info) {//(event, info)
    const boundings = this.boundingClientRectToObj(this.myRef.current.getBoundingClientRect())
    boundings.x = boundings.x - ((window.innerWidth - this.props.containerWidth) / 2)

    this.props.onDrag(boundings, this.props.item)

    if (this.state.snapToOrigin) {
      this.setState({
        snapToOrigin: false
      });
    }

  }

  boundingClientRectToObj(o) {
    const { top, right, bottom, left, width, height, x, y } = o

    const myX = x// + (width / 2)
    const myY = y// + (height / 2)

    return { top, right, bottom, left, width, height, x: myX, y: myY }
  }



  handleDrag(action, event, info) {
    window.scrollTo(0, 0); 
    const boundings = this.boundingClientRectToObj(this.myRef.current.getBoundingClientRect())

    //console.log('this.btnElement.offsetTop',this.btnElement.offsetTop)
    //let offsetTop = this.btnElement.offsetTop
    //console.log(action, event, info)
    this.setState({
      dragAction: action
    });

    if (action == 'end') {


      if (this.state.snapToOrigin) {
        this.setState({
          dragAction: ''
        });
      } else {

        this.setState({
          constraint: {}
        })
        this.props.onEnd(boundings, this.props.item)//event, info
        this.setDraggable(false)

      }

    } else if (action == 'start') {

      // Hack per fixare glitch di framer motion. Con React 18 si presenta un flickering dell'item draggabile allo start del drag.
      let me = this;
      setTimeout(function () {
        me.setState({
          constraint: me.props.constraint
        })

      }, 1)

      //this.props.onStart(event, boundings, offsetTop)
      this.props.onStart(boundings, this.props.item)
    }
  }

  showTooltip() {
    console.log('showTooltip')
    if (this.state.isRepositioning) {
      const boundings = this.boundingClientRectToObj(this.myRef.current.getBoundingClientRect())

      this.setDraggable(false)
      this.props.onEnd(boundings, this.props.item)
      return
    }

    this.setState({
      isTooltipShown: true
    });

    this.tooltipRef.current.style.left = '-50%'
    this.tooltipRef.current.style.right = 'auto'

    new Promise((resolve) => resolve()).then(() => {
      const rect = this.tooltipRef.current.getBoundingClientRect()

      if (rect.x < 0) {
        console.log('A')
        this.tooltipRef.current.style.left = 0
        this.tooltipRef.current.style.right = 'auto'
      } else if (rect.x + rect.width > window.innerWidth) {
        console.log('B')
        this.tooltipRef.current.style.left = 'auto'
        this.tooltipRef.current.style.right = 0
      }

      this.setState({
        foo: this.state.foo + 1
      })
    })


    this.props.onTooltipShown()
  }

  handleTooltipClick() {
    console.log('handleTooltipClick')
    this.setDraggable(true)
    this.setState({
      //canDrag: true,
      isTooltipShown: false,
      isRepositioning: true
    });

    const boundings = this.boundingClientRectToObj(this.myRef.current.getBoundingClientRect())

    this.props.onRepositionStart(boundings, this.props.item)
  }

  handleTooltipCloseClick() {
    this.setState({
      isTooltipShown: false
    });
  }

  hideTooltip() {
    this.handleTooltipCloseClick()
  }

  setDraggable(drag) {

    this.setState({
      canDrag: drag
    });

    if (!drag) {
      this.setState({
        isRepositioning: false
      });

    }

    //this.draggableChanged(drag)
  }
  /*
    startDrag(event) {
      console.log('START DRAG')
      this.dragControls.start(event, { snapToCursor: true })
    }
  */
  render() {

    const shortTitle = this.props.item.title || (this.props.item.description.substring(0, 8) + '...')

    return (
      <div className={(this.props.curItem == this.props.idx ? 'dragitem active' : 'dragitem notactive') + ' ' + (this.state.isTooltipShown ? 'tooltipActive' : '') + (this.state.dragAction == 'start' ? ' isItemDragging ' : '') + (this.state.isRepositioning ? ' isItemRepositioning ' : '')} >


        <motion.div

          className={'test1 ' + this.state.dragAction}
          drag={this.state.canDrag && !this.props.dragDisabled}
          dragMomentum={false}
          dragElastic={true}
          onDrag={(event, info) => {  window.scrollTo(0, 0); this.onDrag(event, info) }}
          dragSnapToOrigin={this.state.snapToOrigin}
          dragConstraints={this.state.constraint}
          onDragStart={(event, info) => this.handleDrag('start', event, info)}
          onDragEnd={(event, info) => this.handleDrag('end', event, info)}
        >
          <div className='def btn-draggable' ref={(btnElement) => { this.btnElement = btnElement }} ><div dangerouslySetInnerHTML={{ __html: this.props.item.description }}></div></div>
          <div ref={this.myRef} className='dragging' onClick={this.showTooltip.bind(this)}>{shortTitle}<span>+</span></div>

          <div className={'itemtooltip ' + (this.state.isTooltipShown ? 'active' : '')} ref={this.tooltipRef}>
            <div dangerouslySetInnerHTML={{ __html: this.props.item.description }}></div>
            <div className='btns'>
              <div className='btn' onClick={this.handleTooltipClick.bind(this)}><IconHand nopadding={true} color={'#FFA61E'} /> Reposition</div>
              <div className='btn' onClick={this.handleTooltipCloseClick.bind(this)}><IconCross nopadding={true} color={'#FFA61E'} /> Close</div>
            </div>
          </div>

        </motion.div>
      </div>
    );
  }
}