import React, { Component } from 'react';
import IconWrapper from './IconWrapper';

const IconItem = (props) => {
  const color = props.color || '#7F99AB'
  
  return (
    <IconWrapper {...props} >
	    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3814 14.6732H12.9444C14.174 14.6732 15.1666 13.6129 15.1666 12.323V3.68997C15.1666 2.39225 14.174 1.33984 12.9444 1.33984H4.05547C2.82584 1.33984 1.83325 2.39225 1.83325 3.68997V12.323C1.83325 13.6208 2.82584 14.6732 4.05547 14.6732H4.61103H12.3814Z" fill="white" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.49988 5.16699C7.91812 5.16699 7.44653 5.63857 7.44653 6.2203C7.44653 6.80204 7.91814 7.27364 8.49988 7.27364C9.08162 7.27364 9.55322 6.80204 9.55322 6.2203C9.55322 5.63857 9.08163 5.16699 8.49988 5.16699ZM6.44653 6.2203C6.44653 5.08625 7.36587 4.16699 8.49988 4.16699C9.63389 4.16699 10.5532 5.08625 10.5532 6.2203C10.5532 7.35432 9.6339 8.27364 8.49988 8.27364C7.36585 8.27364 6.44653 7.35432 6.44653 6.2203Z" fill={color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.33325 11.5473C5.33325 9.98053 6.85079 8.87402 8.49992 8.87402C10.149 8.87402 11.6666 9.98053 11.6666 11.5473C11.6666 11.8235 11.4427 12.0473 11.1666 12.0473C10.8904 12.0473 10.6666 11.8235 10.6666 11.5473C10.6666 10.7141 9.79746 9.87402 8.49992 9.87402C7.20238 9.87402 6.33325 10.7141 6.33325 11.5473C6.33325 11.8235 6.10939 12.0473 5.83325 12.0473C5.55711 12.0473 5.33325 11.8235 5.33325 11.5473Z" fill={color}/>
      </svg>
    </IconWrapper>
  )
}

export default IconItem