import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Card, Container, CardBody,
  CardTitle, CardSubtitle
} from 'reactstrap';

import { connect } from "react-redux";
import { AnimatePresence } from "framer-motion"
import mainService from 'api/mainService';
import { mainSelectors } from "stores/mainSlice";

import { SimpleCard } from 'components/Layout/SimpleCard'
import { SimplePopup } from 'components/Layout/SimplePopup'
import { SlideInOut } from 'components/Layout/SlideInOut'
import { DashboardTagCard } from 'components/Layout/DashboardTagCard'
import { DashboardMenu } from 'components/Layout/DashboardMenu'
import i18next from "i18next";
import { ATRAIN_ROUTES, getRoute } from 'routes';
import assessmentService from 'api/assessmentService';
import DropDownProjectsMenu from 'components/Layout/DropDownProjectsMenu';
import { setCurrentProject } from "stores/mainSlice";
import { DASHBOARD_BUTTONTYPES, DASHBOARD_ACTIONCARD_BUTTONTYPES, IMPROVEMENTS_RANKING_TYPES } from 'types';
import { storeActions } from 'stores/storeHelpers'
import AnimatedPopup from 'components/AnimatedPopup';

const reportMinRepliesRequired = 2

export class DashboardPageInner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isDataLoaded:false,
      feedbackLoopPopupShow: false,
      dashboardData: { header: {} },
      isNewAssessmentDialogVisible:false
    }
  }

  componentDidMount() {
    this.loadDashboardData()
  }

  loadDashboardData() {
    if (!this.props.currentProjectSelector)
      return
    
    storeActions.setIsLoading(true);
    mainService.getDashboardData(this.props.currentProjectSelector.id).then(res => {
      this.setState({
        dashboardData: res.data
      });
    })
    .catch(err =>{
      storeActions.setErrorMessage(true,err.message)
    })
    .finally(()=>{
      storeActions.setIsLoading(false);
      this.setState({
        isDataLoaded: true
      });
    })
  }

  newAssessmentConfirm(){
    let openItems = (this.state.dashboardData.cards || []).filter(c => c.open && c.type != 'F')
    console.log(openItems)
    if(openItems.length > 0){
      this.setState({
        isNewAssessmentDialogVisible: true
      });
    }else{
      this.newAssessment()
    }
  }

  newAssessmentCancel(){
    this.setState({
      isNewAssessmentDialogVisible: false
    });
  }



  newAssessment() {
    
    storeActions.setIsLoading(true);
    assessmentService.newAssessment(this.props.currentProjectSelector.id).then(resp => {
      console.log('newAssessment', resp)
      this.props.history.push(getRoute(ATRAIN_ROUTES.ASSESSMENT, { assessmentId: resp.data.assessmentId }));
    })
    .catch(err =>{
      storeActions.setErrorMessage(true,err.message)
    })
    .finally(()=>{storeActions.setIsLoading(false);})
  }

  showFeedBackLookPopup() {
    this.setState({
      feedbackLoopPopupShow: true
    })
  }

  hideFeedBackLookPopup() {
    this.setState({
      feedbackLoopPopupShow: false
    })
  }

  feedbackLoopPopupDismiss() {
    this.hideFeedBackLookPopup()
  }

  onButtonClick(btnType, card) {

    switch (btnType) {
      case DASHBOARD_BUTTONTYPES.CompleteSelfAssessment:
        this.cardButtonAssessment(card)
        break;

      case DASHBOARD_BUTTONTYPES.ViewReport:
      case DASHBOARD_BUTTONTYPES.ViewSelfAssessment:
        this.cardButtonReport(card)
        break;

      case DASHBOARD_BUTTONTYPES.ViewFeedback:
        this.cardButtonFeedbackLoop(card)
        break;
      case DASHBOARD_BUTTONTYPES.FollowUp:
        this.cardButtonFollowUp(card)
        break;

      case DASHBOARD_BUTTONTYPES.ReplyNow:
        this.cardButtonFeedbackRequestReply(card)
        break;
      default:
        break;
    }

  }

  cardButtonFeedbackLoop(card) {
    console.log('cardButtonFeedbackLoop')
    this.props.history.push(getRoute(ATRAIN_ROUTES.DEVLOOP, { developmentLoopId: card.developmentLoopId }));
  }

  cardButtonFollowUp(card) {
    console.log('cardButtonFollowUp')
    this.props.history.push(getRoute(ATRAIN_ROUTES.FOLLOWUP, { developmentLoopId: card.developmentLoopId, behaviorMarkerId:0 }));
  }

  cardButtonAssessment(card) {
    console.log('cardButtonAssessment')
    this.props.history.push(getRoute(ATRAIN_ROUTES.ASSESSMENT, { assessmentId: card.assessmentId }));
  }

  cardButtonReport(card) {
    console.log('cardButtonReport')
    this.props.history.push(getRoute(ATRAIN_ROUTES.REPORT, { assessmentId: card.assessmentId }));
  }

  cardButtonFeedbackRequestReply(card) {
    console.log('cardButtonFeedbackRequestReply')
    this.props.history.push(getRoute(ATRAIN_ROUTES.ASSESSMENT, { assessmentId: card.assessmentId }));
  }

  onProjDropDownItemClick(project) {
    console.log('onProjDropDownItemClick', project.id)
    this.props.setCurrentProject(project.id)
    let me = this;
    setTimeout(() => {
      me.loadDashboardData()
    }, 1);
    //console.log('VADO:',getRoute(ATRAIN_ROUTES.DASHBOARD))

  }

  get actionCard() {
    return Object.assign({ buttons: [] }, this.state.dashboardData.header);
  }

  getBehaviorRoute(toptype, openAssessmentId){
    let openDevLoops = this.state.dashboardData.cards.filter(c => c.open && c.type == 'D')
    let skip = openDevLoops.length > 0 ? 0 : 1
    return getRoute(ATRAIN_ROUTES.TOP_BEHAVIORS, { toptype: toptype, assessmentId:openAssessmentId }) + '?skpa=' + skip
  }


  render() {
    const user = this.props.userSelector
    console.log('this.props.userSelector',this.props.userSelector)
    const { users, cards, header } = this.state.dashboardData

    console.log('this.state.dashboardData', this.state.dashboardData)

    const actionCard = this.actionCard;
    const actionCardVisible = actionCard.buttons.length > 0 //this.state.dashboardData.newAssessmentAllowed || this.state.dashboardData.newDevelopmentLoopAllowed
    const emptyDashboard = (cards || []).length == 0 && !actionCardVisible
    console.log('actionCardVisible', actionCardVisible)
    const {isDataLoaded} = this.state

    const openAssessmentId = header && header.openAssessmentId

    console.log('actionCard', actionCard)

    //const {projects} = this.state
    console.log('currentProject', this.props.currentProjectSelector)
    //const loaded = typeof user.Client != 'undefined' && users
    const hasProject = this.props.currentProjectSelector

    const loaded = user && hasProject // && actionCard
    const { t } = i18next

    return (
      <div className='dashboard-wrapper'>



        {loaded && <>

          <DashboardMenu user={user}>
            {user.projects.length > 1 && <DropDownProjectsMenu projects={user.projects} selected={this.props.currentProjectSelector} onItemClick={this.onProjDropDownItemClick.bind(this)} />}
          </DashboardMenu>


          <Container>
            {
              ( isDataLoaded && (emptyDashboard || !hasProject) )&& <div className='card-element action-card card'><div className="card-body text-center" dangerouslySetInnerHTML={{ __html: t('dashboard.empty_dashboard_message') }}></div></div>
            }

            {(actionCard && actionCardVisible) &&

              <Card className={'card-element action-card'}>

                <CardBody className='pb-0'>
                  {actionCard.subtitleResourceName && <CardSubtitle className="text-center mb-1" dangerouslySetInnerHTML={{ __html: t(actionCard.subtitleResourceName) }}></CardSubtitle>}
                  {actionCard.titleResourceName && <CardTitle className="text-center mb-0" tag="h3" dangerouslySetInnerHTML={{ __html: t(actionCard.titleResourceName) }}></CardTitle>}
                  {actionCard.descriptionResourceName && <div className='supTitle text-center text-muted mb-3' dangerouslySetInnerHTML={{ __html: t(actionCard.descriptionResourceName) }}></div>}

                  {actionCard.buttons && actionCard.buttons.map((b, idx) => {

                    switch (b.typeCode) {
                      case DASHBOARD_ACTIONCARD_BUTTONTYPES.newAssessment:
                      case DASHBOARD_ACTIONCARD_BUTTONTYPES.startNow:
                        
                        return <div key={idx} onClick={this.newAssessmentConfirm.bind(this)}>
                          <div className="btn btn-primary w-100 mb-3">{t(b.labelResourceName)}</div>
                        </div>
                        break;
                      case DASHBOARD_ACTIONCARD_BUTTONTYPES.newDevelopmentLoop:
                        return <div key={idx} className="btn btn-blue w-100 mb-3" onClick={this.showFeedBackLookPopup.bind(this)}>{t(b.labelResourceName)}</div>
                        break;
                    }
                  })}

                  {actionCard.footer && <div className='footerText' dangerouslySetInnerHTML={{ __html: t(actionCard.footer) }}></div>}
                </CardBody>

              </Card>

            }
            <div className='tag-cards'>
              {cards && cards.map((card, idx) => {
                let link = null

                if (card.type == 'report') {
                  link = '/report/' + card.assessmentId
                }

                return <DashboardTagCard card={card} key={idx} onButtonClick={this.onButtonClick.bind(this)}>

                </DashboardTagCard>
              })}

              {/*<Link to="/report">
                <DashboardTagCard
                  open={true}
                  suptitle="7 May 2022"
                  title="Assessment"
                  footer="4 out of 7 replies received"
                  users={users}
                >
                </DashboardTagCard>
              </Link>


              <DashboardTagCard
                open={false}
                suptitle="7 May 2022"
                title="Assessment"
                footer="4 out of 7 replies received"
                users={users}
              >

            </DashboardTagCard>*/}



              <AnimatePresence>
                {this.state.feedbackLoopPopupShow &&
                  <SlideInOut className='h-100'>
                    <div className='popup-overlay top-10-popup-overlay'>
                      <SimplePopup className="text-center"
                        title={t('top10.popup.title')}
                        suptitle={t('top10.popup.suptitle')}
                        subtitle={t('top10.popup.subtitle')}
                        onClose={this.feedbackLoopPopupDismiss.bind(this)}
                        showCloseButton={true}
                      >


                        <Link to={this.getBehaviorRoute(IMPROVEMENTS_RANKING_TYPES.TopBlockers, openAssessmentId)}><button className="btn btn-sm w-100 btn-pink mb-3" onClick={this.props.onDismiss} dangerouslySetInnerHTML={{ __html: t('top10.popup.btnBlockers') }}></button></Link>
                        <Link to={this.getBehaviorRoute(IMPROVEMENTS_RANKING_TYPES.BottomEnablers, openAssessmentId)}><button className="btn btn-sm w-100 btn-blue mb-3" onClick={this.props.onDismiss} dangerouslySetInnerHTML={{ __html: t('top10.popup.btnEnablers') }}></button></Link>
                        <Link to={this.getBehaviorRoute(IMPROVEMENTS_RANKING_TYPES.BiggestGap, openAssessmentId)}><button className="btn btn-sm w-100 btn-primary mb-3" onClick={this.props.onDismiss} dangerouslySetInnerHTML={{ __html: t('top10.popup.btnGaps') }}></button></Link>
                        <Link to={this.getBehaviorRoute(IMPROVEMENTS_RANKING_TYPES.All, openAssessmentId)}><button className="btn btn-sm w-100 btn-outline-primary mb-3 text-darkblue" onClick={this.props.onDismiss} dangerouslySetInnerHTML={{ __html: t('top10.popup.btnAll') }}></button></Link>

                      </SimplePopup>
                    </div>
                  </SlideInOut>
                }
              </AnimatePresence>


              <AnimatedPopup visible={this.state.isNewAssessmentDialogVisible} showCloseButton={false}>
                <CardSubtitle dangerouslySetInnerHTML={{ __html: t('dashboard.NewAssessment.popup.confirm_message') }}></CardSubtitle>

                <div>
                    <button onClick={this.newAssessment.bind(this)} className="btn btn-sm btn-outline-primary mr-2">{t('dashboard.NewAssessment.popup.btnConfirm')}</button>
                    
                    <button onClick={this.newAssessmentCancel.bind(this)} className="btn btn-sm btn-primary">{t('dashboard.NewAssessment.popup.btnCancel')}</button>
                </div>


              </AnimatedPopup>

            </div>

          </Container>

          <div class="flex-grow-1"></div>
          <div className="regular disclaimer-text text-center mb-3" dangerouslySetInnerHTML={{ __html: t('dashboard.footerText') }}></div>

        </>
        }
      </div>
    );
  }
}





const mapStateToProps = (state) => {
  return ({
    mainStore: state.main,
    currentProjectSelector: mainSelectors.currentProjectSelector(state),
    userSelector: mainSelectors.userSelector(state)
  })
};

const mapDispatchToProps = { setCurrentProject };

const DashboardPage = connect(mapStateToProps, mapDispatchToProps)(DashboardPageInner);

export { DashboardPage }
