import React, { Component } from 'react';
import { Button } from 'reactstrap';
import ReactTags from 'react-tag-autocomplete'

import { throttle } from "lodash";
import i18next from "i18next";
import {IconCross, IconSmsNotification} from 'components/icons'
import Avatar from './Avatar';

const defaultAvatarUrl = '/img/profile-circle.svg'

export default class SponsorInvitation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedUser:{}
        }
    }

    componentDidMount() {

    }

    selectUser(user){
        this.setState({
            selectedUser:user
        })
    }

    render() {
        const {t} = i18next
        const { users } = this.props
        const { selectedUser } = this.state

        return (
            <div>

                <div>

                    <b className='sponsor-intro-text'>{t('setsponsor.intro')}</b>
                    <div className='sponsor-list'>
                   {
                       users.map( u => {

                        return (
                            <div key={u.userId} className={'sponsor-tag ' + (u.userId == selectedUser.userId ? 'is-selected' : '') } onClick={this.selectUser.bind(this, u)}>
                                <Avatar size="small" user={u}/>
                                <span className='tag-name'>{u.firstName + ' ' + u.lastName}</span>
                            </div>
                        )

                       })
                   }
                    </div>
                    
                    <Button disabled={!selectedUser.userId} className='btn btn-primary w-100 btn-send' onClick={() => { this.props.onSend(selectedUser) }}><IconSmsNotification nopadding={true} displayblock={true} />Send invite</Button>

                </div>

            </div>
        );
    }
}
