import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Container, Card, CardBody, CardSubtitle } from 'reactstrap';
import {Header} from 'components/Layout/Header'
import RaterInvitation from 'components/RaterInvitation';

import {ATRAIN_ROUTES, getRoute} from 'routes'
import AnimatedPopup from 'components/AnimatedPopup';
import i18next from "i18next";
import mainService from 'api/mainService';
import { store } from 'stores/store';
import { IMPROVEMENTS_RANKING_TYPES } from 'types';
import developmentLoopService from 'api/developmentLoopService';

export class InviteRaterDevLoopPage extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loaded:true,
      users:[],
      isDialogVisible:false
    }
  }

  componentDidMount(){
    
  }

  handleSendRatersInvitation(users) {

    
      //let history = this.props.history;
      let assessmentId = this.props.match.params.assessmentId

      let data = {
        assessmentId:assessmentId,
        users,
        markers:store.getState().main.devloopMarkers.map( m => m.id)
      }

      developmentLoopService.create(data).then(resp => {
        this.setState({
          isDialogVisible: true
        })
      }).catch(err =>{

      })
  }

  toggle(){
    console.log('toggle', this.state.isDialogVisible, mainService)
    this.setState({
      isDialogVisible: !this.state.isDialogVisible
    })
  }

  render() {
    const {loaded} = this.state
    const { t } = i18next

    let listType = ''
    
    switch (this.props.match.params.toptype) {
      case IMPROVEMENTS_RANKING_TYPES.TopBlockers:
        listType = 'blocker'
        break;
      case IMPROVEMENTS_RANKING_TYPES.BottomEnablers:
        listType = 'enabler'
          break;
      case IMPROVEMENTS_RANKING_TYPES.BiggestGap:
        listType = 'gap'
        break;

      default:
        listType = 'all'
        break;
    }

//bgcolor="#3397D9"
    return (
      
      <>
      {loaded && <div className={'invite-rater'}>
   
        <Header
          className={listType}
          closeLink={getRoute(ATRAIN_ROUTES.TOP_BEHAVIORS, { toptype: this.props.match.params.toptype, assessmentId:this.props.match.params.assessmentId })}
          >

        </Header>
        
        <Card className={'card-element rounded-big'} >
          <CardBody className=''>
           
            <RaterInvitation onSend={this.handleSendRatersInvitation.bind(this)}/>
            
          </CardBody>
        </Card>

        <AnimatedPopup visible={this.state.isDialogVisible}>
          <CardSubtitle dangerouslySetInnerHTML={{ __html: t('devloop.invite.gotodashboard_text') }}></CardSubtitle>
          <Link to={getRoute(ATRAIN_ROUTES.DASHBOARD)}><button className="btn btn-sm w-100 btn-primary mb-3" dangerouslySetInnerHTML={{ __html: t('devloop.invite.gotodashboard_btn') }}></button></Link>
        </AnimatedPopup>

      </div>

      }
      </>
    );
  }
}

