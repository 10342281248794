import React, { Component } from 'react';
import {IconArrowSquareLeft} from 'components/icons'
import BadgeOpenClose from "components/Layout/BadgeOpenClose";
import BadgeDate from "components/Layout/BadgeDate";

import { Link} from 'react-router-dom';

import i18next from "i18next";

export class ActionHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentCount: 0
    }

  }

  // Coputed props
  /*get enablers() {
    return this.props.competency.behaviorMarker.filter(item => item.isPositiveMarker)
  }*/

  componentDidMount(){

  }

  incrementCounter() {
    this.setState({
      currentCount: this.state.currentCount + 1
    });
  }

  toggleExpand() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  render() {
    const { t } = i18next
    return (
        <header className={'top-header action-header ' + (this.props.className || '' )} style={(this.props.bgcolor) ? {backgroundColor: this.props.bgcolor} : null}>

            <div className="header-icons-wrapper">
              <Link to={this.props.closeLink}>
                <IconArrowSquareLeft/>
                <span className="txt">{t('btnBack')}</span>
              </Link>

              {this.props.dropdownmenu}
              
            </div> 

            <div className='action-header-main'>
              <div>
                <h1>{this.props.title}</h1>
                <h2>{this.props.subtitle && this.props.subtitle}</h2>
                <div className='total-replies'>{this.props.replies}</div>
              </div>
              <div className="badge-wrapper">
                <div>
                  <BadgeOpenClose open={this.props.open}/>
                </div>
                <div>
                  <BadgeDate date={this.props.date}/>
                </div>
              </div>
            </div>
            
            {this.props.children && this.props.children}

        </header>
    );
  }
}
