import axios from 'axios';    
import { getQueryParam } from 'utils';
import { ATRAIN_ROUTES } from 'routes';


const redirectExcludedRoutes = [
    '/claiminvitation',
    '/login'
  ]

try {
    const lang = getQueryParam('lang')// window.location.pathname.split('/')[1]

    if(/^([a-z]{2})-([a-z]{2})$/i.test(lang)){
        sessionStorage.setItem('__appLang', lang);
    }else if(sessionStorage.getItem('__appLang') == null){
        sessionStorage.setItem('__appLang', 'en-US');
    }

  } catch (error) {
    console.warn('Switch to default app language')
  }

//export var API_URL = '/api/';
//export var API_URL = '/api/' + sessionStorage.getItem('__appLang') + '/';
export var API_URL = '/api/';

export const HTTP = axios.create({
    withCredentials: true,
    baseURL: API_URL,
    headers: {
        'Accept-Language' : sessionStorage.getItem('__appLang')
    }
})

// Add a request interceptor
HTTP.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    //config.headers.Authorization = `Bearer ${your_token}`;
    // OR config.headers.common['Authorization'] = `Bearer ${your_token}`;
    //config.baseURL = '/mock/';
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

HTTP.interceptors.response.use((response) => {
    return response;
}, (error) => {
    const { config, response: { status } } = error;

    if (status === 401) {
        if (!redirectExcludedRoutes.some(r => window.location.href.indexOf(r) != -1)) {
            window.location.href = ATRAIN_ROUTES.LOGIN
        }
    }
    return Promise.reject(error);
}
)

window.__HTTP = HTTP

function getItems(){
    console.log('getItems')
    return HTTP.get('../../mock/items.json')
}

/*function getReport(){
    return HTTP.get('../../mock/report.json?v=2')
}*/

function setUserPassword(o){
    return HTTP.get('../../mock/setUserPassword.json')
}

function uploadAvatar(o){
    return HTTP.get('../../mock/uploadAvatar.json')
}


function getCompetencies(){
    return HTTP.get('../../mock/competencies.json?v=2')
}

/*function getTop10(p){
    console.log(p)
    return HTTP.get('../../mock/topmarkers.json')
}*/

function getContacts(p){
    console.log(p)
    return HTTP.get('../../mock/contacts.json')
}

/*function sendRatersInvitationDevLoop(p){
    console.log(p)
    return HTTP.get('../../mock/foo.json')
}*/


function sendSponsorInvitation(p){
    console.log(p)
    return HTTP.get('../../mock/foo.json')
}

function getSponsors(){
    return HTTP.get('../../mock/contacts.json')
}

export {
    getItems,
    setUserPassword,
    uploadAvatar,
    getCompetencies,
    //getTop10,
    getContacts,
    sendSponsorInvitation,
    getSponsors,
    //sendRatersInvitationDevLoop
}