import React, { Component } from 'react';
import assessmentSessionService from 'api/assessmentSessionService';
import {CompetencyFlow} from 'components/BehaviorAssessment/CompetencyItem/CompetencyFlow'

import {SlideInOut} from 'components/Layout/SlideInOut'
import {store} from 'stores/store'
import {ATRAIN_ROUTES, getRoute} from 'routes'
import {storeActions} from 'stores/storeHelpers'

import {AnimatePresence} from "framer-motion"

const FLOW_STEPS = {"ENABLERS": 0, "BLOCKERS" : 1, "DONE" : 2}; 

export class NewAssessmentPage extends Component {
  
  constructor(props) {
    super(props);
    let ud = store.getState()

    this.myRef = React.createRef();
    this.state = {
      assessmentData:{},
      curCompetency:null,
      curFlowStep:null,
      curFlowIsBlocker:null,
      userData:ud.main.user
    }


    console.log('store',)
  }

  componentDidMount() {

    storeActions.setIsLoading(true);
    assessmentSessionService.getAssessmentSession(this.props.match.params.assessmentId).then(resp => {
      console.log('getCompetencies', resp.data, resp.data)
      let cb = () => {} // settata poi nel caso di redirect su pagina invitation

      let bmIdToFind = resp.data.lastBehaviorMarkerId

      /*let lastCompetency = resp.data.competencies.find( c => {
        return c.behaviourMarkers.some(bm => bm.id == bmIdToFind)
      })*/


      const lasCompetencyIdx = resp.data.competencies.findIndex(c => {
        return c.behaviorMarkers.some(bm => bm.id == bmIdToFind)
      });

      let lastCompetency = resp.data.competencies[lasCompetencyIdx]


      let curFlowStep = FLOW_STEPS.ENABLERS
      let curCompetency = null;

      if(lastCompetency){
        let lastBehaviorMarker = lastCompetency.behaviorMarkers.find( bm => bm.id == bmIdToFind )

        let nextCompetencyIdx = 0

        if(resp.data.competencies.length > lasCompetencyIdx + 1){ // Se ho una competency successiva alla mia, proseguo con quella nella variante enablers/blockers a seconda di dove ero
          nextCompetencyIdx = lasCompetencyIdx + 1
          curFlowStep = lastBehaviorMarker.isPositive ? FLOW_STEPS.ENABLERS : FLOW_STEPS.BLOCKERS
        }else if (lastBehaviorMarker.isPositive){ // Se non ho un ulteriore competency, e il mio ultimo marker era positivo, allora torno all'inizio e procedo con i blockers
          nextCompetencyIdx = 0
          curFlowStep = FLOW_STEPS.BLOCKERS
        }else{ // Ho teminato tutti gli step, sia positivi che negativi, rimando a pagina di invitation
          console.log('TERMINATO')
          curFlowStep = FLOW_STEPS.BLOCKERS
          cb = () => {this.handleCompleteFlow()}

        }

        console.log('lastBehaviorMarker',lastBehaviorMarker)
        console.log('lastCompetency',lastCompetency)
        curCompetency = resp.data.competencies[nextCompetencyIdx].id// lastCompetency.id
      }
      //xLastBehaviorMarkerId
      this.setState({
        curFlowStep,
        assessmentData:resp.data,
        curCompetency,
        assessmentSessionId: resp.data.assessmentId
      }, cb)
    })
    .catch(err =>{
      let msg
      try {
        msg = err.response.data
      } catch (error) {
        msg = err.message
      }
      storeActions.setErrorMessage(true,msg)
      this.props.history.push(getRoute(ATRAIN_ROUTES.DASHBOARD));
    })
    .finally(()=>{storeActions.setIsLoading(false);})
  }


  handleCompleteFlow(){
    console.log('handleCompleteFlow')

    //let nextStep = this.state.curFlowStep + 1
    let nextStep = ''
    
    switch (this.state.curFlowStep) {
      case FLOW_STEPS.ENABLERS:
        nextStep = FLOW_STEPS.BLOCKERS
        break;
      case FLOW_STEPS.BLOCKERS:
        nextStep = FLOW_STEPS.DONE
        break;
      default:
        break;
    }


    this.setState({
      curFlowStep:nextStep,
      curCompetency:null
    })

    //if(nextStep > 1){
    if(nextStep == FLOW_STEPS.DONE){
      //this.props.history.push("/dashboard");
      //this.props.history.push("/invite-raters/" + this.state.assessmentSessionId);
      if(this.state.assessmentData.isFeedback){
        this.props.history.push(getRoute(ATRAIN_ROUTES.DASHBOARD));
      }else{
        this.props.history.push(getRoute(ATRAIN_ROUTES.INVITE_RATER,{assessmentId:this.state.assessmentSessionId}));
      }


    }
  }

  render() {
    /*const flow = (this.state.curFlowStep == 0)
    ? <CompetencyFlow isBlocker={false} onCompleteFlow={this.handleCompleteFlow.bind(this)} competencies={this.state.CompetencyModel.Competencies}/>
    : <CompetencyFlow isBlocker={true} onCompleteFlow={this.handleCompleteFlow.bind(this)} competencies={this.state.CompetencyModel.Competencies}/>*/
    let flow = null
console.log('this.state.assessmentData',this.state.assessmentData)
    flow = (
              <SlideInOut key={this.state.curFlowStep} className='h-100'>
                    <CompetencyFlow
                        key={this.state.curFlowStep}
                        isBlocker={this.state.curFlowStep}
                        startAtCompetencyId={this.state.curCompetency}
                        onCompleteFlow={this.handleCompleteFlow.bind(this)}
                        assessmentSessionId={this.state.assessmentSessionId}
                        isFeedback={this.state.assessmentData.isFeedback}
                        feedbackReceiver={this.state.assessmentData.feedbackReceiver}
                        avatarUrl={this.state.assessmentData.avatarUrl}
                        competencies={this.state.assessmentData.competencies}
                        userData={this.state.userData}
                        history={this.props.history}
                        />
                        
              </SlideInOut>
          )

    /*switch (this.state.curFlowStep) {
      case FLOW_STEPS.ENABLERS:
        flow = <SlideInOut key="1" className='h-100'><CompetencyFlow key="1" isBlocker={false} startAtCompetencyId={this.state.curCompetency} onCompleteFlow={this.handleCompleteFlow.bind(this)} competencies={this.state.assessmentData.Competencies}/></SlideInOut>
        break;
      case FLOW_STEPS.BLOCKERS:
        flow = <SlideInOut key="2" className='h-100'><CompetencyFlow key="2" isBlocker={true} startAtCompetencyId={this.state.curCompetency} onCompleteFlow={this.handleCompleteFlow.bind(this)} competencies={this.state.assessmentData.Competencies}/></SlideInOut>
        break;
      default:
        break;
    }*/
/*
    let flow1 = null, flow2 = null
    if(this.state.curFlowStep == 0)
      flow1 = <SlideInOut key="1" className='h-100'><CompetencyFlow key="1" isBlocker={false} onCompleteFlow={this.handleCompleteFlow.bind(this)} competencies={this.state.CompetencyModel.Competencies}/></SlideInOut>

    if(this.state.curFlowStep == 1)
      flow2 = <SlideInOut key="2" className='h-100'><CompetencyFlow key="2" isBlocker={true} onCompleteFlow={this.handleCompleteFlow.bind(this)} competencies={this.state.CompetencyModel.Competencies}/></SlideInOut>
*/
    return (
      <div className='assessment-page'>

        <AnimatePresence>
          
          {this.state.assessmentData.competencies && flow}

          {/*(this.state.CompetencyModel.Competencies && this.state.curFlowStep == 0) && flow1*/}
          {/*(this.state.CompetencyModel.Competencies && this.state.curFlowStep == 1) && flow2*/}
          
        </AnimatePresence>
      </div>
    );
  }
}


