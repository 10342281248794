import React, { Component } from 'react';
import { IconSmsNotification } from 'components/icons'

export class CardButton extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        const {name, colorclass} = this.props
        return (

            <div className={'link-button ' + colorclass} onClick={this.props.onClick}>
                <IconSmsNotification nopadding={true} displayblock={true} />
                <span>{name}</span>
            </div>

        );
    }
}
