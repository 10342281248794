import React, { Component } from 'react';
import IconWrapper from './IconWrapper';

const IconItem = (props) => {
  const color = props.color || '#FFFFFF'
  
  return (
    <IconWrapper {...props} >
	    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.85461 18.3335C6.85461 18.3335 4.79864 14.8079 3.39329 12.2021C1.98795 9.59627 2.43037 9.26415 3.41932 8.82985C4.01789 8.57437 5.18901 9.85174 5.76156 10.5415C5.86566 10.6693 5.83963 10.5926 5.83963 10.4138V1.82984C5.83963 1.29335 6.30808 0.833496 7.08883 0.833496C7.63535 0.833496 8.1038 1.2678 8.1038 1.82984V6.14737L8.49418 5.76415C8.78045 5.48313 9.19685 5.3043 9.61324 5.3043C10.0296 5.3043 10.42 5.45758 10.7323 5.76415C10.8885 5.91744 10.9926 6.14736 10.9926 6.37729V7.16926L11.3049 6.86269C11.5131 6.65832 11.8254 6.53058 12.1377 6.53058C12.45 6.53058 12.7623 6.65832 12.9705 6.86269C13.2047 7.09262 13.3348 7.39919 13.3348 7.73131V8.7532L13.543 8.54882C13.7772 8.3189 14.0895 8.19116 14.4279 8.19116C14.7662 8.19116 15.0785 8.3189 15.3127 8.54882C15.5209 8.7532 15.625 9.00868 15.625 9.2897V14.297C15.625 14.9868 15.4689 15.651 15.1566 16.2642L14.0635 18.3335H6.85461Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    </IconWrapper>
  )
}

export default IconItem
