import React, { Component } from 'react';
import { CompetencyItem } from 'components/BehaviorAssessment/CompetencyItem/CompetencyItem'
import { HalfCardLayout } from 'components/Layout/HalfCardLayout'
import { motion, AnimatePresence } from "framer-motion"
import i18next from "i18next";

import { IconTimer } from 'components/icons'

export class CompetencyFlow extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      CompetencyModel: {},
      curCompetency: 0,
      flowStarted: false//,
      //filteredCompetencies: []
    }

  }

  handleBtnNext() {
    if (this.state.curCompetency >= this.props.competencies.length - 1) {
      this.props.onCompleteFlow()
    } else {
      this.setState({
        curCompetency: this.state.curCompetency + 1
      })
    }
  }

  startFlow() {
    this.setState({
      flowStarted: true
    })
  }

  componentDidMount() {

    window.tt = i18next;
    console.log('i18next',i18next)

    if (this.props.startAtCompetencyId) {
      let index = this.props.competencies.findIndex(c => {
        return c.id === this.props.startAtCompetencyId;
      });

      if (index < 0)
        index = 0

      this.setState({
        curCompetency: index
      })

    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isBlocker !== this.props.isBlocker) {
      this.setState({
        curCompetency: 0,
        flowStarted: false
      })
    }
  }

  render() {
    const {t} = i18next
    
    const ownerCtx = (this.props.isFeedback) ? 'rater' : 'owner'
    const blockerCtx = (this.props.isBlocker) ? 'blockers' : 'enablers'

    console.log('ownerCtx',ownerCtx )
    const titleStartTextCtx = (this.props.isBlocker || this.props.startAtCompetencyId) ? 'continue' : 'new'

    const tokens = {
      rater:{
        name:this.props.userData.firstName,
        surname:this.props.userData.lastName,
      },
      focus:{
        name:this.props.feedbackReceiver.firstName,
        surname:this.props.feedbackReceiver.lastName,
      }
    }

    const startCard = (
      <motion.div
        className='h-100 inner-wrapper'
        animate={{ translateY: 0, opacity: 1 }}

        exit={{ translateY: -20, opacity: 0, position: 'absolute' }}
        transition={{ duration: .35 }}>

        <HalfCardLayout
          bgsuptext={(this.props.isBlocker) ? t('blockers') : t('enablers')}
          bgimage="/img/card-bg.jpg"
          bgtitle={t('assessment.startPage.title', { context: titleStartTextCtx })}
          bgtext={t('assessment.startPage.subTitle_' + blockerCtx, { context: ownerCtx, ...tokens  })}
        >

          <div className={'text-center ' + (this.props.isBlocker ? 'blockers' : '')}>
            <h3 className="halfcard__title emp mt-4 pb-2 text-capitalize h4 ">{t('assessment.startPage.card_title', { context: ownerCtx, ...tokens })}</h3>

            <p className="halfcard__subtitle card-intro-text">
             {t('assessment.startPage.card_subTitle', { context: ownerCtx, ...tokens })}
            </p>

            <button onClick={this.startFlow.bind(this)} className="btn btn-primary w-100 mt-2">{t('assessment.startPage.card_button')}</button>

            <div className="halfcard__footertext text-center">
              {/*<IconTimer color="#7f99ab" /> {t('assessment.startPage.card_timeLeft')}*/}
            </div>
          </div>
        </HalfCardLayout>
      </motion.div>
    )

    const CompetencyItems = (this.props.competencies && (
      <CompetencyItem
          key={this.props.isBlocker ? 2 : 1}
          isBlocker={this.props.isBlocker}
          competency={this.props.competencies[this.state.curCompetency]}
          assessmentSessionId={this.props.assessmentSessionId}
          avatarUrl={this.props.avatarUrl}
          ownerCtx={ownerCtx}
          tokens={tokens}
          onNext={this.handleBtnNext.bind(this)}
          history={this.props.history}
          />
    )

    )

    return (
      <AnimatePresence>
        <div className={(this.props.isBlocker) ? 'blockers' : 'enablers'}>

          {this.state.flowStarted && CompetencyItems}
          
          {!this.state.flowStarted && startCard}

        </div>
      </AnimatePresence>
    );
  }
}


