import React, { Component } from 'react';
import { Button, Label, Input } from 'reactstrap';
import i18next from "i18next";
import ContactTag from 'components/ContactTag'
import { IconSmsNotification, IconProfileCircle } from 'components/icons'
import {isValidEmail} from 'utils'
import invitationService from 'api/invitationService';
import { stripHtml } from 'utils';

export default class RaterInvitation extends Component {

    constructor(props) {
        super(props);
        this.onInputchange = this.onInputchange.bind(this);
        this.state = {
            ratersToInvite: [],
            raterName:'',
            raterSurname:'',
            raterEmail:'',
            errorMessageVisible:'',
            errorMessage:'',
            mailMessage:''
        }
    }

    
    componentDidMount() {
        
        invitationService.raterSuggest(this.props.assessmentId).then(res => {

            const ratersToInvite = res.data

            this.setState({
              ratersToInvite
            })

        })

    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleRemoveRater(email) {
        console.log('handleRemoveRater',email)
        const idx = this.state.ratersToInvite.findIndex(item => item.email === email);
        console.log('idx', idx)
        if (idx > -1) {
            const ratersToInvite = this.state.ratersToInvite.slice(0)
            ratersToInvite.splice(idx, 1)
            this.setState({ ratersToInvite })
        }

    }
    addRater(event){
        console.log('addRater')
        event.preventDefault();
        const ratersToInvite = this.state.ratersToInvite.slice(0)

        if(!ratersToInvite.some(r => {return r.email == this.state.raterEmail})){
            ratersToInvite.push({
                firstName:this.state.raterName,
                lastName:this.state.raterSurname,
                email:this.state.raterEmail
            })
        }

        this.setState({
            ratersToInvite,
            raterName:'',
            raterSurname:'',
            raterEmail:''
        })

    }

    handleSend(){
          
        this.props.onSend(this.state.ratersToInvite, stripHtml(this.state.mailMessage))
    }

    get isFormFilled(){
        let valid = this.state.raterName != '' && this.state.raterSurname != '' && this.state.raterEmail != '' && isValidEmail(this.state.raterEmail)
        return valid
      }

    render() {
        const { ratersToInvite } = this.state
        const {t} = i18next
        const btnAdd = this.props.btnAddKey || 'addreater.btnAdd'
        const btnSend = this.props.btnSendKey || 'addreater.btnSend'
        return (
            <div>


                 
                <form className="mt-4 mb-2" onSubmit={this.addRater.bind(this)}>
                    <div className="form-group">
                        <Label for="raterName">{t('addrater.lblname')}</Label>
                        <Input
                            name="raterName"
                            placeholder=""
                            type="text"
                            className='form-control main'

                            value={this.state.raterName}
                            onChange={this.onInputchange}
                        />
                    </div>
                    <div className="form-group">
                        <Label for="raterSurname">{t('addrater.lblsurname')}</Label>
                        <Input
                            name="raterSurname"
                            placeholder=""
                            type="text"
                            className='form-control main mb-1'

                            value={this.state.raterSurname}
                            onChange={this.onInputchange}
                        />
                    </div>
                    <div className="form-group mb-3">
                        <Label for="raterEmail">{t('addrater.lblemail')}</Label>
                        <Input
                            name="raterEmail"
                            placeholder=""
                            type="text"
                            className='form-control main mb-1'
                            value={this.state.raterEmail}
                            onChange={this.onInputchange}
                        />
                    </div>
                    <Button disabled={!this.isFormFilled} type="submit" color="primary" outline="true" className="btn w-100 mb-3 btn-icon btn-addrater"><IconProfileCircle color="#000" style={{width:'20px'}} nopadding={true} displayblock={true} />{t(btnAdd)}</Button>


                    {ratersToInvite.length > 0 && <div className="form-group my-3 pt-3">
                        <Label className='mb-2' for="ratersToInvite">{t('addrater.lblRatersToInvite')}</Label>
                        <div>
                            {ratersToInvite.map(rater => {
                                return <ContactTag key={rater.email} email={rater.email} onRemove={() => this.handleRemoveRater(rater.email)} />
                            })}
                        </div>
                    </div>}


                    <div className="form-group my-3 pt-3">
                        <Label for="raterEmail">{t('addrater.lblEmailMessage')}</Label>
                        <Input
                            name="mailMessage"
                            placeholder={t('addrater.lblEmailMessageHint')}
                            type="textarea"
                            className='form-control main mb-1 mailMessage'
                            value={this.state.mailMessage}
                            rows="3"
                            onChange={this.onInputchange}
                        />
                    </div>

                    <div className={'fieldErrorMessage text-danger text-center mb-3 ' + (this.state.errorMessageVisible ? 'd-shown' : 'd-hidden')}>{this.state.errorMessage}</div>
                 
                    <Button disabled={this.state.ratersToInvite.length == 0} className='btn btn-primary w-100 btn-send btn-icon' onClick={this.handleSend.bind(this)}><IconSmsNotification nopadding={true} displayblock={true} />{t(btnSend)}</Button>
                </form>

                

            </div>
        );
    }
}