import React, { Component } from 'react';
import { Route, Switch, useLocation, Redirect, withRouter } from 'react-router';


import { Layout } from 'components/Layout';
import Home from 'components/Home';
import { CardElement } from 'components/CardElement';

import { ReportPage } from 'pages/ReportPage';
import { DashboardPage } from 'pages/DashboardPage';
import { NewAssessmentPage } from 'pages/NewAssessmentPage';
import { ClaimInvitationPage } from 'pages/ClaimInvitationPage';
import { InviteRaterPage } from 'pages/InviteRaterPage';
import { InviteSponsorPage } from 'pages/InviteSponsorPage';
import { InviteMoreRatersPage } from 'pages/InviteMoreRatersPage';
import { SetAvatarPage } from 'pages/SetAvatarPage';
import { LoginPage } from 'pages/LoginPage';
import { DevloopPage } from 'pages/DevloopPage';
import { InviteRaterDevLoopPage } from 'pages/InviteRaterPageDevLoop';

import { Provider } from 'react-redux';
import { store } from 'stores/store';
import {ATRAIN_ROUTES} from 'routes'
import { ForgotPasswordPage } from 'pages/ForgotPasswordPage';
import { ResetPasswordPage } from 'pages/ResetPasswordPage';
import 'styles/scss/style.scss'
import { TopBlockers } from 'pages/TopBlockers';
import { FollowUpPage } from 'pages/FollowUpPage';

function App(Component) {
  const InjectedTodos = function (props) {
    let location = useLocation();

    return <AppInner {...props} location={location} />;
  };
  return InjectedTodos;
}

class AppInner extends Component {
  
  render() {
    let location = this.props.location

    return (
      
        <Provider store={store}>
          <Layout location={location}>

            <Switch location={location}>
            <Route path={ATRAIN_ROUTES.FORGOT_PASSWORD}    component={ForgotPasswordPage} />
            <Route path={ATRAIN_ROUTES.RESET_PASSWORD}    component={ResetPasswordPage} />
            
            <Route path={ATRAIN_ROUTES.FOLLOWUP}          component={FollowUpPage} />
              <Route path={ATRAIN_ROUTES.LOGIN}             component={LoginPage} />
              <Route path={ATRAIN_ROUTES.CLAIM_INVITATION}  component={ClaimInvitationPage} />
              
              <Route exact path='/'                         render={() => (<Redirect to={ATRAIN_ROUTES.DASHBOARD} />)}></Route>
              <Route path={ATRAIN_ROUTES.REPORT}            component={ReportPage} />
              <Route path={ATRAIN_ROUTES.DEVLOOP}          component={DevloopPage} />
              <Route path='/card'                           component={CardElement} />
              <Route path={ATRAIN_ROUTES.DASHBOARD}         component={DashboardPage} />
              <Route path={ATRAIN_ROUTES.TOP_BEHAVIORS}     component={TopBlockers} />
              <Route path={ATRAIN_ROUTES.ASSESSMENT}        component={NewAssessmentPage} />
              <Route path={ATRAIN_ROUTES.INVITE_RATER_DEVLOOP}    component={InviteRaterDevLoopPage} />
              <Route path={ATRAIN_ROUTES.INVITE_RATER}      component={InviteRaterPage} />
              <Route path={ATRAIN_ROUTES.INVITE_SPONSOR}    component={InviteSponsorPage} />
              <Route path={ATRAIN_ROUTES.INVITE_MORE_RATERS}    component={InviteMoreRatersPage} />
              

              <Route path={ATRAIN_ROUTES.SET_AVATAR}        component={SetAvatarPage} />
              
              
              
            </Switch>

          </Layout>
        </Provider>
      
    );
  }
}


export default App(AppInner);