import React, { Component } from 'react';
import { Container, Card, CardBody, Button, CardSubtitle } from 'reactstrap';
import {Header} from 'components/Layout/Header'
import Slider from 'components/Slider';
import {improvableMarkers} from 'api/index';
import { ATRAIN_ROUTES, getRoute } from 'routes';
import i18next from "i18next";

import { connect } from "react-redux";
import { mainSelectors } from "stores/mainSlice";
import { setDevloopMarkers } from "stores/mainSlice";
import {storeActions} from 'stores/storeHelpers'
import { IMPROVEMENTS_RANKING_TYPES } from 'types';
import developmentLoopService from 'api/developmentLoopService';
import { stripHtml } from 'utils';
import { Link } from 'react-router-dom';
import AnimatedPopup from 'components/AnimatedPopup';
import { getQueryParam } from 'utils';

class TopBlockersInner extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      requiredCount:null,
      behaviorMarkers:[],
      isDialogVisible:false
    }
  }


  componentDidMount(){
    storeActions.setIsLoading(true)
    developmentLoopService.improvableMarkers(this.props.match.params.assessmentId,this.props.match.params.toptype).then(res =>{
      console.log(res.data)
    //const persons = res.data;
      this.setState({
          behaviorMarkers:res.data.markers,
          requiredCount:res.data.requiredCount
      });
    }).catch(()=>{})
    .finally(()=>{
      storeActions.setIsLoading(false)
    })
    
  }

  selectBehavior(behaviorMarker){
    const selCount = this.state.behaviorMarkers.filter(bm => bm.isSelected).length;
    const totCount = this.state.requiredCount
    if(!behaviorMarker.isSelected && selCount >= totCount){
      return
    }

    behaviorMarker.isSelected = !behaviorMarker.isSelected
    console.log('CLICK!', behaviorMarker)

    this.setState({
        behaviorMarkers:[...this.state.behaviorMarkers]
    });
  }

  newDevloopConfirm(){
    let skip = getQueryParam('skpa')

    if(skip == '1'){
      this.newDevloop();
    }else{
      this.setState({
        isNewDevLoopDialogVisible: true
      });
    }

    
  }

  newDevloopCancel(){
    this.setState({
      isNewDevLoopDialogVisible: false
    });
  }

  newDevloop(){
  
    //let m = this.state.behaviorMarkers.filter(bm => bm.isSelected) 
    let markers = this.state.behaviorMarkers.filter(bm => bm.isSelected).map( m => m.id)
    //this.props.setDevloopMarkers(m)

    //let history = this.props.history;
    let assessmentId = this.props.match.params.assessmentId

    let data = {
      assessmentId:assessmentId,
      //users,
      markers
    }

    storeActions.setIsLoading(true)
    developmentLoopService.create(data).then(resp => {
      this.setState({
        isDialogVisible: true,
        isNewDevLoopDialogVisible: false
      })
    }).catch(err =>{

    }).finally(()=>{
      storeActions.setIsLoading(false)
    })



    //this.props.history.push(getRoute(ATRAIN_ROUTES.INVITE_RATER_DEVLOOP,{toptype:this.props.match.params.toptype, assessmentId:this.props.match.params.assessmentId}));
    
    

  }

  render() {
    //const loaded = true; //typeof user.Client != 'undefined'
    
    const { t } = i18next
    const items = this.state.behaviorMarkers
    const selCount = this.state.behaviorMarkers.filter(bm => bm.isSelected).length
    const requiredCount = this.state.requiredCount
    let listType = ''
    
    switch (this.props.match.params.toptype) {
      case IMPROVEMENTS_RANKING_TYPES.TopBlockers:
        listType = 'blocker'
        break;
      case IMPROVEMENTS_RANKING_TYPES.BottomEnablers:
        listType = 'enabler'
          break;
      case IMPROVEMENTS_RANKING_TYPES.BiggestGap:
        listType = 'gap'
        break;

      default:
        listType = 'all'
        break;
    }

//bgcolor="#3397D9"

// negative: 
    return (
      
      <>
      {items && <div className={'topbehaviors-wrapper ' + (listType + '-list')}>
   
        <Header
          className={listType}
          title={t('top10.page_title_' + this.props.match.params.toptype)}
          subtitle={t('top10.page_subtitle',{requiredCount})}
          closeLink={getRoute(ATRAIN_ROUTES.DASHBOARD)}
          closeText={t('top10.page_closeText')}
          >

        </Header>
        
        <Container className='topbehaviors-list'>
        {items.map(i => { 
            
            return (
              <Card key={i.id} className={'card-element ' + (i.isSelected ? ' selected' : '') + ' ' + (i.positive ? '' : 'blocker-list')} onClick={this.selectBehavior.bind(this,i)} >
                <CardBody className='pt-0 pb-1'>
                  <Slider title={stripHtml(i.title)} amount={i.ratersScore} amount2={i.personScore} color={i.positive ? '#3397D9' : '#d71c8b'} toLeft={!i.positive}/>
                </CardBody>
              </Card>
            )
        })}
        </Container>

        <div className='bottom-toolbar'>
          <div className='bottom-toolbar-inner'>

          <div className='info-text'>{t('top10.page_currently_selected',{selCount, requiredCount}) }</div>
          <Button disabled={selCount != requiredCount} onClick={this.newDevloopConfirm.bind(this)} className='btn btn-primary btn-sm'>{t('top10.page_btnNext')}</Button>

          </div>
        </div>

        <AnimatedPopup visible={this.state.isDialogVisible}>
          <CardSubtitle dangerouslySetInnerHTML={{ __html: t('top10.popup.gotodashboard_text') }}></CardSubtitle>
          <Link to={getRoute(ATRAIN_ROUTES.DASHBOARD)}><button className="btn btn-sm w-100 btn-primary mb-3" dangerouslySetInnerHTML={{ __html: t('top10.popup.gotodashboard_btn') }}></button></Link>
        </AnimatedPopup>


        <AnimatedPopup visible={this.state.isNewDevLoopDialogVisible} showCloseButton={false}>
          <CardSubtitle dangerouslySetInnerHTML={{ __html: t('top10.NewDevLoop.popup.confirm_message') }}></CardSubtitle>

          <div>
              <button onClick={this.newDevloop.bind(this)} className="btn btn-sm btn-outline-primary mr-2 mb-2">{t('top10.NewDevLoop.popup.btnConfirm')}</button>
              
              <button onClick={this.newDevloopCancel.bind(this)} className="btn btn-sm btn-primary mb-2">{t('top10.NewDevLoop.popup.btnCancel')}</button>
          </div>


        </AnimatedPopup>

      </div>

      }
      </>
    );
  }
}





const mapStateToProps = (state) => {
  return ({
    mainStore: state.main,
    devloopMarkersSelector: mainSelectors.devloopMarkersSelector(state),
  })
};

const mapDispatchToProps = {setDevloopMarkers};

const TopBlockers = connect(mapStateToProps, mapDispatchToProps)(TopBlockersInner);

export { TopBlockers }