import React, { Component } from 'react';

export class SvgCircles extends Component {
  
  render () {
    return (
        <svg className={this.props.className} style={this.props.style} xmlns="http://www.w3.org/2000/svg"
        fill="#FFF" stroke="#2099F0" strokeWidth="0" viewBox="-100 -100 200 200">
            <circle fill={this.props.colors[0]} r="100"/>
            <circle fill={this.props.colors[1]} r="83"/>
            <circle fill={this.props.colors[2]} r="67"/>
            <circle fill={this.props.colors[3]} r="49"/>
            <circle fill={this.props.colors[4]} r="31"/>
            {/*<circle fill="#163345" r="12"/>*/}

        </svg>
        
    );
  }
}


