import React, { Component } from 'react';

import {SvgCircles} from './SvgCircles'

export class BackgroundCircles extends Component {
  
  handleResize = function(){

    if(!this.divElement){
      return
    }
    console.log('handleResize')
    const height = this.divElement.clientHeight;
    const width = this.divElement.clientWidth;

    console.log('height',height, 'width', this.divElement.clientWidth)
    this.setState({
      height,
      width
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      height:0,
      width:0,
      enablersColors:['#b3d8f1','#7fbee7','#5cace1','#45a0dc','#3397d9'],
      blockersColors:['#efa4d0','#e361af','#dd3c9c','#da2a92','#d71c8b']
    }

    

  }



  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize.bind(this))
   
  }


  componentWillUnmount(){
    try{
      window.removEventListener('resize', this.handleResize)
    }catch{
      console.warn('Error removing window.removEventListener')
    }
    
  }


  render() {

    const colors = this.props.isBlocker ? this.state.blockersColors : this.state.enablersColors
    return (
     
        <div className='circle-offset'>
          <div className='behavior-circles-header'>

          

          </div>
          <div className='circleSvgWrapper' ref={ (divElement) => { this.divElement = divElement } }>

            <SvgCircles className="svgCircle" style={{
              left: ((this.state.height + 50) - (this.state.width / 2) ) * -1,
              top: - (this.state.height) - 100, // metà altezza avatar...
                height: (this.state.height)* 2 + 100
              }}
              
              colors={colors}
              />

                {this.props.children}
          </div>

        
          </div>
            
    );
  }
}