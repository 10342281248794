import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import ReactTags from 'react-tag-autocomplete'
import invitationService from 'api/invitationService';
import { throttle } from "lodash";
import {t} from "i18next";
import { stripHtml } from 'utils';

import {IconCross, IconSmsNotification} from 'components/icons'

const defaultAvatarUrl = '/img/profile-circle.svg'

export default class FollowUpSuggest extends Component {

    constructor(props) {
        super(props);

        this.onInputchange = this.onInputchange.bind(this);

        this.state = {
            tags: [],
            suggestions: [],
            busy: false,
            selectedMarker:this.props.defaultMarker,
            mailMessage:''
        }
    }

    componentDidMount() {


        invitationService.userSuggest(this.props.developmentLoopId).then(res => {

            const suggestions = res.data.map(u => {
                return { ...u, name:u.email, id: u.userId, avatarUrl: (u.avatarUrl || defaultAvatarUrl) }
            })

            this.setState({
                suggestions
            })

        })

    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    componentWillUnmount() {
    }

    onMarkerChanged(e) {
        console.log('onMarkerChanged', e.target.value)
        this.setState({
            selectedMarker: e.target.value,
        });
    }

    handleDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags })
    }

    handleAddition(tag) {
        console.log('handleAddition', tag, typeof tag.id)

        let myFullTag;
        if (tag.id > 0) {
            myFullTag = this.state.suggestions.find(s => s.id == tag.id)
            console.log(myFullTag)
        } else {
            let emailRegExp = new RegExp('^(.+)@(.+)\\.(.+)$')
            console.log('emailRegExp', emailRegExp)
            if (!emailRegExp.test(tag.name))
                return

            myFullTag = {
                //"userId": null,
                //"name": tag.name,
                "email": tag.name,
                //"avatarUrl": defaultAvatarUrl
            }
        }

        const tags = [].concat(this.state.tags, myFullTag)
        this.setState({ tags })
    }

    handleSuggestionsTransform(query, suggestions){
        return suggestions.filter(r => (r.email.indexOf(query) != -1) )
    }

    /*
    handleInputChange = throttle((query) => {
        if (query == ''){
            console.log('esco');
            return
        }
    
            invitationService.userSuggest(query).then(res => {

                const suggestions = res.data.map(u => {
                    //return { ...u, name:u.firstName + ' ' + u.lastName, id: u.userId, avatarUrl: (u.avatarUrl || defaultAvatarUrl) }
                    return { ...u, name:u.email, id: u.userId, avatarUrl: (u.avatarUrl || defaultAvatarUrl) }
                })

                this.setState({
                  //  busy: false,
                    suggestions
                })

            })
   
    },300)
    */


    render() {
        const { tags, suggestions, selectedMarker } = this.state
        const {markers} = this.props

        return (
            <div className='followUpSuggestWrapper'>
                <div className='followUpSuggestBlock'>

                <div className="followUpSuggestIntro">{t('devloop.followup.chooseMarker')}</div>

                {markers.map((item, idx) => (
                     <React.Fragment key={idx.toString()}>
                            <input 
                                type="radio"
                                name="selectedMarker"
                                id={'chk_' + item.behaviorMarkerId}
                                value={item.behaviorMarkerId}
                                checked={this.state.selectedMarker == item.behaviorMarkerId}
                                onChange={this.onMarkerChanged.bind(this)}
                            /> <label htmlFor={'chk_' + item.behaviorMarkerId}>{item.shortTitle}</label>
                    </React.Fragment>
                ))}

                </div>

                <div>

                    <b className='text-primary'>Email</b>
                    <ReactTags
                        tags={tags}
                        allowNew={true}
                        suggestionsTransform={this.handleSuggestionsTransform.bind(this)}
                        placeholderText=''
                        noSuggestionsText={'No suggestions found'}
                        suggestions={suggestions}
                        onDelete={this.handleDelete.bind(this)}
                        onAddition={this.handleAddition.bind(this)}
                        tagComponent={TagComponent} />


<div className="form-group my-3 pt-3">
                        <b className='text-primary'>{t('devloop.followup.lblEmailMessage')}</b>
                        <Input
                            name="mailMessage"
                            placeholder=""
                            type="textarea"
                            className='form-control main mb-1 mailMessage'
                            value={this.state.mailMessage}
                            rows="3"
                            onChange={this.onInputchange}
                        />
                    </div>

                    <Button  disabled={this.state.tags.length == 0} className='btn btn-primary w-100 btn-send' onClick={() => { this.props.onSend(tags,selectedMarker,stripHtml(this.state.mailMessage)) }}><IconSmsNotification nopadding={true} displayblock={true} />Send invite</Button>
                </div>

            </div>
        );
    }
}


export function TagComponent({ tag, removeButtonText, onDelete }) {
    return (


        <div className='contact-tag'>
            <div className="contact-tag-inner">
                <span className='tag-name'>{tag.email}</span>
                <span className='btn-remove-contact' onClick={onDelete}>
                    <IconCross nopadding={true} color="#ffa61e" />
                </span>
            </div>
        </div>


    )
}