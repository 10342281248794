import React, { Component } from 'react';
import IconWrapper from './IconWrapper';

const IconItem = (props) => {
  const color = props.color || '#FFFFFF'
  
  return (
    <IconWrapper {...props} >
<svg width="20" height="25" viewBox="5 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1027_2296)">
<path d="M16.8814 16.6732H17.4444C18.674 16.6732 19.6666 15.6129 19.6666 14.323V5.68997C19.6666 4.39225 18.674 3.33984 17.4444 3.33984H8.55547C7.32584 3.33984 6.33325 4.39225 6.33325 5.68997V14.323C6.33325 15.6208 7.32584 16.6732 8.55547 16.6732H9.11103H16.8814Z" fill={color}/>
<path d="M16.8814 16.6732H17.4444C18.674 16.6732 19.6666 15.6129 19.6666 14.323V5.68997C19.6666 4.39225 18.674 3.33984 17.4444 3.33984H8.55547C7.32584 3.33984 6.33325 4.39225 6.33325 5.68997V14.323C6.33325 15.6208 7.32584 16.6732 8.55547 16.6732H9.11103H16.8814Z" stroke="#163345" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<path fillRule="evenodd" clipRule="evenodd" d="M14.0934 6.44542C14.6085 6.622 14.9659 7.18168 14.8519 7.74059L14.715 8.61684H15.8599C16.2452 8.61684 16.605 8.77446 16.8291 9.07857C17.0542 9.38403 17.0963 9.77546 16.9665 10.145L16.1732 12.5579C16.086 12.884 15.8746 13.1445 15.6344 13.3203C15.3896 13.4995 15.0798 13.6168 14.7599 13.6168H13.4933C13.345 13.6168 13.1738 13.5932 13.0142 13.5476C12.8756 13.508 12.686 13.4352 12.5345 13.2972L11.5205 12.5122L12.1327 11.7215L13.1911 12.5409L13.2037 12.5535C13.2054 12.5544 13.2077 12.5557 13.2106 12.5571C13.2272 12.5654 13.2535 12.5759 13.289 12.5861C13.3627 12.6072 13.4416 12.6168 13.4933 12.6168H14.7599C14.8401 12.6168 14.9469 12.5842 15.0438 12.5133C15.1404 12.4427 15.192 12.3603 15.2082 12.2956L15.2126 12.2779L16.0203 9.82131L16.0224 9.81533C16.0564 9.72017 16.031 9.68113 16.0241 9.67177C16.0148 9.65921 15.9747 9.61684 15.8599 9.61684H14.5266C14.031 9.61684 13.6207 9.18923 13.6983 8.64612L13.6992 8.63964L13.8684 7.55725L13.8718 7.5417C13.8735 7.53404 13.8752 7.50475 13.8508 7.46377C13.8266 7.42297 13.7931 7.3994 13.7685 7.39118L13.7597 7.38825L13.751 7.385C13.7491 7.38426 13.7212 7.37709 13.6725 7.39385C13.6506 7.40137 13.6321 7.41141 13.6191 7.42082C13.6122 7.42576 13.6085 7.42929 13.6072 7.43065L12.2416 9.46242L11.4116 8.90458L12.7779 6.8719C12.9173 6.66329 13.1332 6.5218 13.3474 6.44816C13.5629 6.37408 13.8315 6.34995 14.0934 6.44542Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M10.6358 8.72962C10.6313 8.75317 10.6267 8.79164 10.6267 8.85013V12.1168C10.6267 12.1753 10.6313 12.2138 10.6358 12.2373C10.6656 12.2438 10.7155 12.2501 10.7934 12.2501H11.1267C11.2046 12.2501 11.2544 12.2438 11.2843 12.2373C11.2888 12.2138 11.2934 12.1753 11.2934 12.1168V8.85013C11.2934 8.79164 11.2888 8.75317 11.2843 8.72962C11.2544 8.72308 11.2046 8.7168 11.1267 8.7168H10.7934C10.7155 8.7168 10.6656 8.72308 10.6358 8.72962ZM9.9459 7.99106C10.1953 7.76088 10.5217 7.7168 10.7934 7.7168H11.1267C11.3984 7.7168 11.7248 7.76088 11.9742 7.99106C12.2334 8.23031 12.2934 8.56046 12.2934 8.85013V12.1168C12.2934 12.4065 12.2334 12.7366 11.9742 12.9759C11.7248 13.206 11.3984 13.2501 11.1267 13.2501H10.7934C10.5217 13.2501 10.1953 13.206 9.9459 12.9759C9.68671 12.7366 9.62671 12.4065 9.62671 12.1168V8.85013C9.62671 8.56046 9.68671 8.23031 9.9459 7.99106Z" fill="white"/>
<defs>
<filter id="filter0_d_1027_2296" x="0.833252" y="0.839844" width="24.3333" height="24.333" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="2.5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0880208 0 0 0 0 0.198149 0 0 0 0 0.270833 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1027_2296"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1027_2296" result="shape"/>
</filter>
</defs>
</svg>
    </IconWrapper>
  )
}

export default IconItem