import React, { Component } from 'react';
import i18next from "i18next";
import profileService from 'api/profileService'
import Avatar from 'components/Avatar';
import { dataURLToBlob } from 'utils';

export class SetAvatar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      imgPreview: this.props.user.avatarUrl,
      showUploadButton: true,
      isPreviewInProcess: false,
      avatarResizeTo:512
    }

    this.hiddenFileInput = React.createRef();
    this.previewImage = React.createRef();

  }

  goToNext(event) {
    event && event.preventDefault();
    //console.log('goToNext')
    this.props.onNext({setAvatar:false})
  }


  handleUpload() {












    profileService.UploadAvatar(this.state.fileToUpload).then(resp => {
      this.props.onNext({setAvatar:true, avatarUrl:resp.data.url})
      //this.goToNext({avatarUrl:resp.data.url})
    })
  }



  render() {
    const {t} = i18next
    //const {users, competency, totalReplies} = this.state.reportData
    const { user } = this.props
    const { showUploadButton } = this.state
    const goToNextText = this.props.isregistration ? t('setAvatar.continue_without_photo') : t('changeAvatar.backtodashboard')
    const shortName = user.firstName[0] + user.lastName[0]

    const handleClick = event => {
      this.hiddenFileInput.current.click();
    };

    const handleChange = event => {

      let me = this;

      // Read in file
    var file = event.target.files[0];

    // Ensure it's an image
    if(file.type.match(/image.*/)) {
        console.log('An image has been loaded');

        // Load the image
        var reader = new FileReader();
        reader.onload = function (readerEvent) {
            var image = new Image();
            image.onload = function (imageEvent) {

                // Resize the image
                var canvas = document.createElement('canvas'),
                    max_size = me.state.avatarResizeTo,
                    width = image.width,
                    height = image.height;
                if (width > height) {
                    if (width > max_size) {
                        height *= max_size / width;
                        width = max_size;
                    }
                } else {
                    if (height > max_size) {
                        width *= max_size / height;
                        height = max_size;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                var dataUrl = canvas.toDataURL('image/jpeg');
                var resizedImage = dataURLToBlob(dataUrl);




                
      me.setState({ isPreviewInProcess: true })
      //const fileUploaded = event.target.files[0];
      //props.handleFile(fileUploaded);

      me.setState({
        fileToUpload: resizedImage,//event.target.files[0],
        imgPreview: URL.createObjectURL(event.target.files[0]),
        showUploadButton: false,
        isPreviewInProcess: false
      })





            }
            image.src = readerEvent.target.result;
        }
        reader.readAsDataURL(file);
    }









    };

    return (
      <div>

        <h3 className="mt-4 pb-2 text-capitalize h4">{t('setAvatar.page_title')}</h3>

        <p className="card-intro-text">
        {t('setAvatar.page_subtitle')}
        </p>

        <div className="my-4 pt-2 text-center">
          <Avatar user={{...user, avatarUrl:this.state.imgPreview}}/>
          {/*<div className={'avatar unset ' + (this.state.isPreviewInProcess ? 'processing' : '')} style={this.state.imgPreview && { backgroundImage: 'url(' + this.state.imgPreview + ')' }}>
            {!this.state.imgPreview && <span>{shortName}</span>}
          </div>*/}

        </div>

        {showUploadButton && <div class="inputFileWrapper">
          <input
            type="file"
            accept="image/*"
            name="contained-button-file"
            id="contained-button-file"
           
            ref={this.hiddenFileInput}
            onChange={handleChange}
          />

          <label htmlFor="contained-button-file" className="d-block">
            <button onClick={handleClick} className="btn btn-primary w-100">{t('setAvatar.btnUpload')}</button>
          </label>
        </div>}

        {!showUploadButton && <button onClick={this.handleUpload.bind(this)} className="btn btn-primary w-100">{t('setAvatar.btnGo')}</button>}

        <div className="text-center mt-3 mb-1">
          <strong className="d-block text-muted">{t('setAvatar.or')}</strong>
          <a href="#" onClick={this.goToNext.bind(this)}>{goToNextText}</a>
        </div>

      </div>
    );
  }
}