import React, { Component } from 'react';

import {Badge
} from 'reactstrap';

export default class BadgeOpenClose extends Component {
  render() {
    //const dateBadge = <Badge color="light" pill className='dimmed'>{this.props.date}</Badge>
    return (
        this.props.open ? <Badge color="light" pill className='custom-badge text-uppercase'>{this.props.label || 'OPEN'}</Badge> : <Badge color="light" pill className='custom-badge dimmed text-uppercase'>{this.props.label || 'CLOSE'}</Badge>
    );
  }
}