export const DASHBOARD_CARDTYPES = {
    Assessment:'A',
    FeedbackRequest:'F',
    DevelopmentLoop:'D'
}

export const DASHBOARD_ACTIONCARD_BUTTONTYPES = {
    newDevelopmentLoop:'newdevelopmentloop',
    newAssessment:'newassessment',
    startNow:'startnow'
}

export const DASHBOARD_BUTTONTYPES = {
    CompleteSelfAssessment:'assessment.completeselfassessment',
    ViewSelfAssessment:'assessment.viewselfassessment',
    ViewReport:'assessment.viewreport',
    ReplyNow:'feedbackrequest.replynow',
    ViewFeedback:'developmentloop.viewfeedback',
    FollowUp:'developmentloop.followup'
}

export const IMPROVEMENTS_RANKING_TYPES = {
    All:'A',
    TopBlockers:'B',
    BottomEnablers:'E',
    BiggestGap:'G'
}