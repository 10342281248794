import React, { Component } from 'react';
import {HalfCardLayout} from 'components/Layout/HalfCardLayout'
import { LoginForm } from 'components/UserCreate/LoginForm';
import i18next from "i18next";
import {ATRAIN_ROUTES} from 'routes'
import { setPrjFromQs } from 'utils';
import { Link } from 'react-router-dom';

export class LoginPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      invitationToken:null,
      isPageVisible: true,
      step:'',
      user:{
        firstName:''
      }
    }
  }

  handleNext(o){
    var nextStep = ''
    console.log('handleNext 0')

    setPrjFromQs()

    this.props.history.push(ATRAIN_ROUTES.DASHBOARD);
    
  }

  componentDidMount(){

  }
   
  render() {
    const {isPageVisible, user, step, invitationToken} = this.state
    const {t} = i18next

    return (
     <div className='setavatar-wrapper' >
        {isPageVisible && 
        <HalfCardLayout
          halfheight={true}
          bgimage="/img/card-bg.jpg"
          bgtitle={t('login.claimTitle')}
        >
        
            <LoginForm onLoginDone={this.handleNext.bind(this)}/>

            <div className='text-center'><Link to={ATRAIN_ROUTES.FORGOT_PASSWORD}>{t('login.btnForgotPassword')}</Link></div>

        </HalfCardLayout>
      }
      </div>
    );
  }
}