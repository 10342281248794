import React, { Component } from 'react';
import profileService from 'api/profileService';
import {UncontrolledAlert } from 'reactstrap';

import { connect } from "react-redux";
import { setUser, setCurrentProject, setIsLoading } from "stores/mainSlice";
import {eventBus} from "utils";
import { LoadSpinner } from 'components/LoadSpinner';

import {storeActions} from 'stores/storeHelpers'

import {store} from 'stores/store'
import { ATRAIN_UNAUTHENTICATED_ROUTES } from 'routes';

const unauthenticatedRoutes = ATRAIN_UNAUTHENTICATED_ROUTES

class LayoutInner extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      authDataLoaded: false
    }
  }

  componentDidMount() {

    /*try {
      window.__appLang = this.props.location.pathname.split('/')[1]
    } catch (error) {
      window.__appLang = 'en-US'      
    }*/
    
    this.getData()

  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.getData()
    }
  }

  getData() {


    console.log('>>>>>>>>>>>>>>>>>>>', store)
    if (unauthenticatedRoutes.some(r => r == this.props.location.pathname)) {
      /*axios.get('/mock/foo.json').then(resp => {
        this.props.setUser(resp.data)
      })*/
    } else {
      if (this.state.authDataLoaded)
        return
        //this.props.setIsLoading(true)
        //store.dispatch(setIsLoading(true));
        storeActions.setIsLoading(true)

        profileService.getUserInfo().then(resp => {
        this.props.setUser(resp.data)

        let curPrj = sessionStorage.getItem('currentproject')
        if(!curPrj){
          let defP = resp.data.projects.find(p => p.selected)
          if(defP){
            curPrj = defP.id
          }else{
            curPrj = resp.data.projects[0].id
          }
        }

        this.props.setCurrentProject(curPrj)
        this.setState({
          authDataLoaded:true
        })
      }).catch(err => {
        if(err.response.status == 401){
          console.log(window.history)
          window.location.replace("/login")
        }
      }).finally(()=>{
        //store.dispatch(setIsLoading(false));
        //storeActions.setIsLoading(true)
      //  this.props.setIsLoading(false)
      storeActions.setIsLoading(false)
      })
    }
  }

  render() {
    const showChildren = (unauthenticatedRoutes.some(r => r == this.props.location.pathname) || this.state.authDataLoaded)
console.log(showChildren)
    return (
      <>
        {showChildren && this.props.children}
        <LoadSpinner active={this.props.mainStore.isLoading} />

        {this.props.mainStore.errorMessageVisible && <UncontrolledAlert className='common-alert' color={'danger'}>{this.props.mainStore.errorMessageText}</UncontrolledAlert>}


      </>
    );
  }
}

const mapStateToProps = (state) => {
  return ({
    mainStore: state.main
  })
};

const mapDispatchToProps = { setUser, setCurrentProject, setIsLoading };

const Layout = connect(mapStateToProps, mapDispatchToProps)(LayoutInner);

export { Layout }
