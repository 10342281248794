import React, { Component } from 'react';
import { Container, CardSubtitle } from 'reactstrap';
import { Route, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import reportService from 'api/reportService';
import invitationService from 'api/invitationService';
import {storeActions} from 'stores/storeHelpers'
import { CardElement } from 'components/CardElement'
import { ActionHeader } from 'components/Layout/ActionHeader'
import { IconSmsNotification, IconUserTagOutline, IconUserVoteOutline } from 'components/icons'
import AnimatedPopup from 'components/AnimatedPopup';
import i18next from "i18next";
//import {fullDate} from 'i18n';
import { ATRAIN_ROUTES, getRoute } from 'routes';
import {td} from 'utils'
import DropDownAssessmentMenu from 'components/Layout/DropDownAssessmentMenu';
import { connect } from "react-redux";
import { mainSelectors } from "stores/mainSlice";
import CustomCarousel from 'components/Layout/CustomCarousel';


export class ReportPageInner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentCount: 0,
            isSendReminderDialogVisible:false,
            reportData: {
                competency: []
            }
        }

    }


    
    get isEditable() {
        console.log('isEditable', this.props.currentProjectSelector.open)
        return this.props.currentProjectSelector.open && this.state.reportData.open
      }


    componentDidMount() {

        storeActions.setIsLoading(true)
        reportService.getReport(this.props.match.params.assessmentId).then(res => {
            this.setState({
                reportData: res.data
            });
        }).catch(()=>{})
        .finally(() => {
            storeActions.setIsLoading(false)
        })
    }

    sendReminder(){
        storeActions.setIsLoading(true)
        invitationService.sendReminder(this.props.match.params.assessmentId).then(res => {
           
        }).catch((err)=>{
            storeActions.setErrorMessage(true,err.message)
        })
        .finally(() => {
            storeActions.setIsLoading(false)
            this.setState({isSendReminderDialogVisible:false})
        })

    }

    
    toggleReminderPupoup(){
        this.setState({
            isSendReminderDialogVisible:!this.state.isSendReminderDialogVisible
        })
    }


    render() {
        const { t } = i18next
        const assessmentId = this.props.match.params.assessmentId
        const { users, competencies, feedbackReceived, invitedRaters, showYourBiggestCards, yourBiggestCards } = this.state.reportData
        const { path } = this.props.match
        const replies = invitedRaters > 0 ? t('report.repliesreceived', { feedbackReceived, invitedRaters }) :  t('report.noratersinvited')

        const menu = this.isEditable && <DropDownAssessmentMenu open={this.state.reportData.open} assessmentId={assessmentId}/>

        return (
            <div className='report-wrapper'>
 <ActionHeader
                    className="report-header"
                    title={t('report.title')}
                    date={td(this.state.reportData.startDate)}
                    closeLink={getRoute(ATRAIN_ROUTES.DASHBOARD)}
                    replies={replies}
                    open={this.state.reportData.open}
                    dropdownmenu={menu}
                >

                    {this.isEditable && <div>
                        <hr className='mb-0 mt-3'/>

                        <div className="header-buttons">                        
                            <div className='report-header-actionlink' onClick={this.toggleReminderPupoup.bind(this)}><IconSmsNotification nopadding={true} /> <span>{t('report.btnReminder')}</span></div>
                            {invitedRaters == 0 && <div className='report-header-actionlink'><Link to={getRoute(ATRAIN_ROUTES.INVITE_RATER,{assessmentId})}><IconSmsNotification nopadding={true} /> <span>{t('report.btnAddRater')}</span></Link></div>}
                            {invitedRaters > 0 && <div className='report-header-actionlink'><Link to={getRoute(ATRAIN_ROUTES.INVITE_MORE_RATERS,{assessmentId})}><IconSmsNotification nopadding={true} /> <span>{t('report.btnAddMoreRater')}</span></Link></div>}
                            
                        </div>

                    </div>}
                </ActionHeader>

                {showYourBiggestCards && <CustomCarousel cards={yourBiggestCards}/>}

                        <Container>

                           
                            <div className="report-legend">
                                <div>
                                    <IconUserTagOutline nopadding={true} /> <span>{t('report.legend_myself')}</span>
                                </div>
                                <div>
                                    <IconUserVoteOutline nopadding={true} /> <span>{t('report.legend_they')}</span>
                                </div>
                            </div>

                            {competencies && competencies.map((item,idx) => <CardElement competency={item} key={idx} />)}
                        </Container>
      

                        <AnimatedPopup visible={this.state.isSendReminderDialogVisible} showCloseButton={true} onCloseButtonClick={this.toggleReminderPupoup.bind(this)}>
                            <CardSubtitle>{t('report.sendreminder.popup.title')}</CardSubtitle>
                            <button onClick={this.sendReminder.bind(this)} className="btn btn-sm w-100 btn-primary mb-3">{t('report.sendreminder.popup.btnSend')}</button>
                        </AnimatedPopup>


            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return ({
      mainStore: state.main,
      currentProjectSelector: mainSelectors.currentProjectSelector(state)
    })
  };
  
  const mapDispatchToProps = {};
  
  const ReportPage = connect(mapStateToProps, mapDispatchToProps)(ReportPageInner);
  
  export { ReportPage }
  