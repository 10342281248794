import React, { Component } from 'react';
import {SimplePopup} from 'components/Layout/SimplePopup'
import {IconTimer} from 'components/icons'
import i18next from "i18next";
import { motion } from "framer-motion"

export class CompetencyOverlay extends Component {
   
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
   
  }

  render() {
    const {t} = i18next

    return (
      
        <motion.div
            className='competency-overlay'
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0  }}
            transition={{ duration: 0.35 }}>
      {/*footer={<div><IconTimer color="#7f99ab"/> {t('assessment.startPage.card_timeLeft')}</div>}*/}
            <SimplePopup className="text-center"
              title={this.props.competencyName}
              suptitle={(this.props.isBlocker) ? t('blockers') : t('enablers')}
              subtitle={this.props.subtitle}
              
              onClose={this.props.onDismiss}
              showCloseButton={true}
              >
                
                <button className="btn btn-sm btn-primary" onClick={this.props.onDismiss}>{t('assessment.competency_popup.btnStart')}</button>
                <div className="halfcard__footertext text-center">
              
            </div>
            </SimplePopup>          
      </motion.div>
    );
  }
}