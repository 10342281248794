import { HTTP } from 'api'

const basePath = "/RaterInvitation/";

export default {

  sendRatersInvitation(assessmentId, p){
    console.log(p)
    return HTTP.post(basePath.concat(assessmentId), p)
  },
  
  sendReminder(assessmentId){
    return HTTP.patch(basePath.concat(assessmentId))
  },

  userSuggest(developmentLoopId){
    //return HTTP.get(basePath, {params:{Query:term}})
    return HTTP.get('/FeedbackPersonInvitation/' + developmentLoopId)
  },

  raterSuggest(assessmentId){
    return HTTP.get('/RaterInvitation/' + assessmentId)
  },

  raterList(assessmentId){
  
    return HTTP.get("/assessments/".concat(assessmentId,'/raters'));
  
  }
  
}