import React, { Component } from 'react';
import {HalfCardLayout} from 'components/Layout/HalfCardLayout'
import { ResetPasswordForm } from 'components/UserCreate/ResetPasswordForm';
import {ATRAIN_ROUTES,getRoute} from 'routes'
import i18next from "i18next";

export class ResetPasswordPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      invitationToken:null,
      isPageVisible: true,
      step:'resetpassword',
      user:{
        firstName:'',
        lastName:''
      },
      isErrorPage:false, // TODO, validare se il token è corretto prima dell'effettivo invio dei dati?
      errorMessage:''
    }
  }

  handleNext(o){
    /*this.setState({
      step:'resetpassworddone'
    })*/

    setTimeout(()=>{
      this.props.history.push(getRoute(ATRAIN_ROUTES.LOGIN));
    },3000)
    
  }

  componentDidMount(){
    const qs = new URLSearchParams(this.props.location.search)

      const qsLower = new URLSearchParams();
      for (const [name, value] of qs) {
          qsLower.append(name.toLowerCase(), value);
      }

      const invitationToken = qsLower.get('code')
      const userId = qsLower.get('userid')

    this.setState({
      invitationToken,
      userId
    })

  }
   
  
  render() {
    const {t} = i18next
    const {errorMessage, isErrorPage, isPageVisible, step, invitationToken, userId} = this.state
    const bgtitle = t('resetpassword.title')
    const bgtext = isErrorPage ? '' : t('resetpassword.subtitle')

    return (
     <div className='setavatar-wrapper' >
        {isPageVisible && 
        <HalfCardLayout
          halfheight={true}
          bgimage="/img/card-bg.jpg"
          bgtitle={bgtitle}
          bgtext={bgtext}
        >
        
            {step === 'resetpassword' && <ResetPasswordForm onSubmit={this.handleNext.bind(this)} userId={userId} invitationToken={invitationToken}/>}

            {/*step === 'resetpassworddone' && <div>{t('resetpassword.done')}</div>*/}

            {isErrorPage &&  <div>
                <h3 className="mt-4 pb-2 text-capitalize h4 ">{t('resetpassword.invalidurl.title')}</h3>

                <div>
                  <div dangerouslySetInnerHTML={{__html: t('resetpassword.invalidurl.message')}}></div>
                  <pre className='mt-4'>
                    {errorMessage}
                  </pre>
                </div>
            </div>
          }

        </HalfCardLayout>
      }
      </div>
    );
  }
}