import React, { Component } from 'react';
import i18next from "i18next";
import { Label, Input, Button} from 'reactstrap';
import { setPrjFromQs } from 'utils';
import accountService from 'api/accountService'
//import { setProjects } from 'utils';

export class SetPasswordForm extends Component {
  
  constructor(props) {
    super(props);
    this.passwordConfirmRef = React.createRef()
    this.passwordRef = React.createRef()

    this.state = {
      password:'',
      passwordConfirm:'',
      errorMessage:' ',
      errorMessageVisible:false,
      isApiError:false,
      disclaimerChecked:false
    }
    this.onInputchange = this.onInputchange.bind(this);
    this.onDisclaimerChange = this.onDisclaimerChange.bind(this);
  }

  submitPassword(event){
    event.preventDefault();

    console.log('submitPassword!')

    accountService.addPassword(
        this.props.userId,
        this.props.addPasswordCode,
        this.state.password
        )
        .then( resp => {
          
          setPrjFromQs()
          this.props.onSubmit({
            password:this.state.password,
            event
          })
        }).catch(resp =>{
            console.log(resp);
            let errorMessage = ' '

            try {

              if(Array.isArray(resp.response.data)){
                errorMessage = resp.response.data[0].description
              }else if (resp.response.data.errors){
                errorMessage = i18next.t(resp.response.data.errors.Password[0])
              }
  
            } catch (error) {
              errorMessage = 'Error'
            }
           
            this.setState({
              errorMessageVisible:true,
              errorMessage
            })

        })
    
  }

  onDisclaimerChange(event) {

    this.setState({
      disclaimerChecked: !this.state.disclaimerChecked
    })


  }

  onInputchange(event) {

    this.setState({
      [event.target.name]: event.target.value
    },() =>{
     
      let mismatch = this.state.passwordConfirm != '' && ( this.state.passwordConfirm != this.state.password)
      let msg = null

      if(mismatch){
        msg = i18next.t('setpassword.validation.password_mismatch')
      }

      this.setState({
        errorMessage: (msg || this.state.errorMessage),
        errorMessageVisible: msg != null
      })
      
    });

  }
  
  get isValidPassword(){
    let valid = this.state.password != '' && (this.state.password == this.state.passwordConfirm)
    console.log(valid)
    return valid
  }

  get isPasswordMismatch(){
    let mismatch = this.state.passwordConfirm != '' && (this.state.password != this.state.passwordConfirm)
    return mismatch
  }

  render() {
    //const {users, competency, totalReplies} = this.state.reportData
    const {t} = i18next

    return (
      <div>
          <h3 className="mt-4 pb-2 text-capitalize h4 ">{t('setpassword.page_title')}</h3>
          <div className='small'>{t('setpassword.form_passwordformatHint')}</div>
          <form className="mt-4 mb-2">
						<div className="form-group">
							<Label for="password">{t('setpassword.form_lblPassword')}</Label>
							<Input
                  ref={this.passwordRef}
                  name="password"
                  placeholder=""
                  type="password"
                  className='form-control main'

                  value={this.state.password}
                  onChange={this.onInputchange}
                />
						</div>
            
						<div className="form-group mb-0">
              <Label for="passwordConfirm">{t('setpassword.form_lblPasswordConfirm')}</Label>
              <Input
                  ref={this.passwordConfirmRef}
                  name="passwordConfirm"
                  placeholder=""
                  type="password"
                  className='form-control main mb-1'

                  value={this.state.passwordConfirm}
                  onChange={this.onInputchange}
                />
                {/*<div className={'text-danger text-center mb-1 ' + (this.isPasswordMismatch ? 'd-shown':'d-hidden')}>Le due password non coincidono</div>*/}
                <div className={'d-inline-block text-danger text-center mb-0 ' + (this.state.errorMessageVisible ? 'd-shown':'d-hidden')}>{this.state.errorMessage} </div>
						</div>

            <label className='chkDisclaimer'>
              <input
                type="checkbox"
                checked={this.state.disclaimerChecked}
                onChange={this.onDisclaimerChange}
                value="1"
              />&nbsp;
              <span className="regular disclaimer-text text-center user-select-none" dangerouslySetInnerHTML={{ __html: t('setpassword.footerText') }}></span>
            </label>

            
	
            <Button disabled={!this.isValidPassword || !this.state.disclaimerChecked} onClick={this.submitPassword.bind(this)} className="btn btn-primary w-100">{t('setpassword.btnConfirm')}</Button>
					</form>

          
      </div>
    );
  }
}