import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Nav, NavItem, NavLink, TabPane, TabContent, CardBody, Card, CardSubtitle } from 'reactstrap';
import i18next from "i18next";
//import {fullDate} from 'i18n';
import { Slider } from 'components/Slider';
import { SimpleCard } from 'components/Layout/SimpleCard';
import { ATRAIN_ROUTES, getRoute} from 'routes';
import developmentLoopService from 'api/developmentLoopService';
import { ActionHeader } from 'components/Layout/ActionHeader';
import {stripHtml, td} from 'utils'
import { IconSmsNotification } from 'components/icons';
import DropDownDevloopMenu from 'components/Layout/DropDownDevloopMenu';
import { connect } from "react-redux";
import { mainSelectors } from "stores/mainSlice";

export class DevloopPageInner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            devloopData: {
                markers: []
            }
        }
    }

    get isEditable() {
        return this.props.currentProjectSelector.open && this.state.devloopData.open
      }


    componentDidMount() {
        
        developmentLoopService.getDevloop(this.props.match.params.developmentLoopId).then(res => {
            this.setState({
                devloopData: res.data
            });
        })
    }

    handleTabClick(tab){
        console.log(tab);
        this.setState({
            activeTab: tab
        })
    }

    render() {
        const { t } = i18next

        const { activeTab, devloopData, devloopData: {markers} } = this.state
        const { path } = this.props.match

        const menu = this.isEditable && <DropDownDevloopMenu open={devloopData.open} developmentLoopId={this.props.match.params.developmentLoopId}/>

        return (
            <div className='feedback-wrapper'>

                <ActionHeader
                    className="feedback-header enabler"
                    title={t('devloop.title')}
                    date={td(devloopData.startDate)}
                    closeLink={getRoute(ATRAIN_ROUTES.DASHBOARD)}
                    replies={t('devloop.replies', { feedbackReceived: devloopData.feedbackReceived})}
                    open={devloopData.open}
                    dropdownmenu={menu}
                >

                   
                </ActionHeader>


                {/*<Header
                    className="feedback-header"
                    title={t('report.title')}
                    closeLink={getRoute(ATRAIN_ROUTES.DASHBOARD)}
                >

                    <div>
                     
                        <div className='total-replies'>
                            {t('feedback.replies', { feedbackReceived: devloopData.feedbackReceived})}
                        </div>


                    </div>
        </Header>*/}

       
                <Nav>
                    {markers.map((item, idx) => (
                        <NavItem  key={idx.toString()}>
                            <NavLink
                                className={ activeTab == idx ? 'active' : ''}
                                onClick={this.handleTabClick.bind(this, idx)}>
                                    {item.shortTitle}
                            </NavLink>
                        </NavItem>
                    ))}
                    
                </Nav>
                        
                <Container>

              
                <TabContent activeTab={activeTab}>

                    {markers.map((item, idx) => {
                        
                        const color = item.positive ? "#3397D9" : "#D71C8B"
                        const toLeft = item.positive ? false : true

                        const regex = /(<([^>]+)>)/ig;
                        const strippedTitle = stripHtml(item.description);

                        return (

                        <TabPane key={idx.toString()} tabId={idx}>
            
                            <Slider title={strippedTitle} amount={item.ratersScore} amount2={item.personScore} color={color} bigger={true} toLeft={toLeft} barClass={item.positive ? '' :'blocker'}/>
                        
                            {this.isEditable && item.canFollowUp && <div className="grey-card">
                                <h5 className="title">{t('devloop.followupcard.title')}</h5>
                                <div className="body-text">
                                {t('devloop.followupcard.text')}
                                </div>
                                
                                <Link className={'link-button text-blue'} to={getRoute(ATRAIN_ROUTES.FOLLOWUP,{developmentLoopId: this.props.match.params.developmentLoopId, behaviorMarkerId:item.behaviorMarkerId})}>
                               
                                    <IconSmsNotification color="currentColor " nopadding={true} displayblock={true} />
                                    <span>{t('devloop.followupcard.btnFollowUp')}</span>
                         
                                </Link>

                            </div>}

                            <div className="mt-3">
                                {item.feedbacks && item.feedbacks.map(feedback => {



                                        return (<Card className={'card-element devloop-feedback-card'}>
                                       
                                            <CardBody>
                                                <div className='supTitle'>{t('devloop.feedbacks.date',{receivedDate:td(feedback.receivedDate)})}</div>
                                                <CardSubtitle dangerouslySetInnerHTML={{ __html: feedback.feedback }}></CardSubtitle>
                                            </CardBody>
                                      
                                        </Card>)



                                })}
                            </div>


                           
                        </TabPane>)
                        }
                    )}

                </TabContent>
                </Container>
              
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return ({
      mainStore: state.main,
      currentProjectSelector: mainSelectors.currentProjectSelector(state)
    })
  };
  
  const mapDispatchToProps = {};
  
  const DevloopPage = connect(mapStateToProps, mapDispatchToProps)(DevloopPageInner);
  
  export { DevloopPage }