import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BadgeOpenClose from "components/Layout/BadgeOpenClose";
import {
  Card, Badge, CardBody,
  CardTitle, CardSubtitle
} from 'reactstrap';
import i18next from "i18next";
import { CardButton } from 'components/Dashboard/CardButton';
import { DASHBOARD_CARDTYPES } from 'types';
import {td} from 'utils'
//import {SimpleCard} from 'components/Layout/SimpleCard'



export class DashboardTagCard extends Component {

  constructor(props) {
    super(props);

  }

  render() {
    const { t } = i18next
    const {card} = this.props
    
    let cssClass = 'white'

    switch (card.type) {
      case DASHBOARD_CARDTYPES.DevelopmentLoop:
        cssClass = 'blue'
        break;
      case DASHBOARD_CARDTYPES.Assessment:
        cssClass = 'primary'
        break;
      default:
        break;
    }
    const suptitle = (card.type == DASHBOARD_CARDTYPES.FeedbackRequest) ? (t(card.labelSenderResourceName,{requesterName:card.feedbackReceiver})) : null
    const title = t(card.titleResourceName)
    const subtitle = t(card.subtitleResourceName,{feedbackReceived:card.feedbackReceived, invitedRaters: card.invitedRaters})
    const open = card.open
    const dateBadge = <Badge color="light" pill className='dimmed text-uppercase'>{td(card.startDate)}</Badge>
    const showOpenCloseTag = card.type != DASHBOARD_CARDTYPES.FeedbackRequest ? true : false
    const cssOpenClass = this.props.open ? 'tag-open' : 'tag-close'

    const btnColorClass = (card.type == DASHBOARD_CARDTYPES.FeedbackRequest) ? 'text-pink' : 'xxxxx'

    let buttons = card.buttons && card.buttons.map((btn, idx) => {
      return <CardButton key={idx}  name={t(btn.labelResourceName)} btntype={btn.typeCode} onClick={this.props.onButtonClick.bind(this, btn.typeCode, card)} colorclass={btnColorClass} />
    })

    return (
      
      <Card className={'card-element dashboard-tag-card ' + cssOpenClass + ' ' + cssClass + ' cardtype_' + card.type}>
        <CardBody>
            {suptitle && <div className='supTitle' dangerouslySetInnerHTML={{__html: suptitle}}></div>}
            {title && <CardTitle className="mb-0" tag="h3" dangerouslySetInnerHTML={{__html: title}}></CardTitle>}

            <div className="badgeWrapper">
              {showOpenCloseTag && <div>
                <BadgeOpenClose open={open} label={t(card.statusResourceName)}/>
              </div>}
              <div>{dateBadge}</div>
            </div>
            {subtitle && <div className='footerText mt-0' dangerouslySetInnerHTML={{__html: subtitle}}></div>}

            <hr/>

            <div className='buttons'>
                {buttons}

                {this.props.children}
            </div>
            
        </CardBody>
      </Card>

    );
  }
}



            
