import { HTTP } from 'api'

const basePath = "/Assessment/";

export default {

  /*saveCompetencyStep(markerScores, assessmentSessionId){
    return HTTP.get('/mock/foo.json?markerScores=' + JSON.stringify(markerScores) + '&assessmentSessionId=' + assessmentSessionId))
  },*/

  newAssessment(projectId){
    return HTTP.post(basePath.concat(projectId));
  },

  getAssessments(){
    return HTTP.get(basePath)
  },

  closeAssessment(assessmentId){
    return HTTP.patch(basePath.concat('Close/',assessmentId));
  },

}