import { HTTP } from 'api'

const basePath = "/AssessmentReport/";

export default {

  /*saveCompetencyStep(markerScores, assessmentSessionId){
    return HTTP.get('/mock/foo.json?markerScores=' + JSON.stringify(markerScores) + '&assessmentSessionId=' + assessmentSessionId))
  },*/

  /*newAssessment(assessmentId){
    return HTTP.post(basePath.concat(assessmentId));
  },*/

  getReport(assessmentId){
    console.log('getReport')
    return HTTP.get(basePath,{params:{assessmentId}})
  }
}