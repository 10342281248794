import React, { Component } from 'react';

export class HalfCardLayout extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false
    }

  }

  get bgStyle() {
    return {
      backgroundImage:'url(' + this.props.bgimage + ')'
      }
  }

  render () {
    const {bgtitle, bgtext, cardtitle, cardsubtitle, bgimage, bgsuptext} = this.props
    
    return (
      <div className='halfcard-wrapper' >

        <section className="section halfcard-bg" style={this.bgStyle}>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-11 col-md-6 order-2 order-md-1 text-md-left mx-2">
                { bgsuptext && <div className='halfcard-bg__suptitle h5' dangerouslySetInnerHTML={{__html: bgsuptext}}></div>}
                <h1 className="halfcard-bg__title text-white mb-4" dangerouslySetInnerHTML={{__html: bgtitle}}></h1>
                <div className="halfcard-bg__subtitle mb-1" dangerouslySetInnerHTML={{__html: bgtext}}></div>
              </div>
            </div>
          </div>
        </section>

        <section className="section py-0 bottomhalf-card">
            <div className={'rounded-big shadow px-4 pb-4 bg-white ' + ((this.props.halfheight) ? 'half-height' : '')}>
              <div className="row bottomhalf-card-row">
                <div className="col-lg-4 col-md-6 mt-1 mt-md-0 px-4 bottomhalf-card-inner">
                  { cardtitle && <div className='halfcard__title h1' dangerouslySetInnerHTML={{__html: cardtitle}}></div>}
                  { cardsubtitle && <div className='halfcard__subtitle' dangerouslySetInnerHTML={{__html: cardsubtitle}}></div>}

                  {this.props.children}


                </div>
              </div>
            </div>
        </section>

      </div>
    );
  }
}
