import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default class CustomCarousel extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
        active:0    }

}

componentDidMount() {
}


  render() {
    const cards = this.props.cards

    return (
<div className='custom-carousel'>
      <Carousel showThumbs={false} showArrows={false} showStatus={false}>

      { cards && cards.map((card,idx) => <div key={idx}  style={{backgroundImage:'url(/static-assets/slides/' + card.backgroundImage + ')'}}>
          <div className='topText'>
            <span dangerouslySetInnerHTML={{ __html: card.subtitle }}></span> <b  dangerouslySetInnerHTML={{ __html: card.title }}></b>
          </div>

          <div className='bottomText' dangerouslySetInnerHTML={{ __html: card.competencyName }}></div>
      </div>)}



      
  </Carousel>
  </div>
  
       
    );
  }
}



            
